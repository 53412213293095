import React, {useState, useEffect, useRef} from "react";
import './AdminProgress.css'


export function AdminProgress(props){

    var total = props.total ?? 1;

    var progress = props.progress ?? 0;
    var width = (progress / total) * 100

    var progress2 = props.progress2 ?? 0;
    var width2 = (progress2 / total) * 100

    return(
        <div className={`admin-progress ${props.className ? props.className : ''}`}>
            <div className="admin-progress-container">
                {/* <span className="admin-progress-value-label">{`${progress}-${[progress2]} / ${total}`}</span> */}
                <div className="admin-progress-value" style={{width: `${width}%`}}>
                    <span className="admin-progress-value-label">{progress}</span>
                </div>
                <div className="admin-progress-value2" style={{width: `${width2}%`}}>
                    <span className="admin-progress-value-label">{progress2}</span>
                </div>
                <span className="admin-progress-value-end">{`${total}`}</span>

            </div>
            <div className="admin-progress-labels">
            { props.label ? <span className="admin-progress-label"><div className="label1-colour"/> {`${props.label} - ${progress}`}</span> : null }
            { props.label2 ? <span className="admin-progress-label"><div className="label2-colour"/> {`${props.label2} - ${progress2}`}</span> : null }
            </div>
        </div>
    )
}