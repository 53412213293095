import React, { useState, useContext } from "react";
import HubContext from "utils/hubProvider";
import { Button } from "components";
import { getSessionId } from "utils/helper";
import { useButtonTransition } from "hooks/useButtonTransition";
import quizApi from "services/QuizApi";
import { APIProvider, Map, Marker } from '@vis.gl/react-google-maps';
import mapStyles from "views/MapStyles";
import styles from './Inputs.module.css';

export function MapInput(props) {
    const [answer, setAnswer] = useState(props.answer?.answer.map(e => parseFloat(e)));
    const hub = useContext(HubContext);
    const [error, setError] = useState(null);
    const { transition, transitionContent } = useButtonTransition()
    const [fullscreen, setFullScreen] = useState(false)

    async function submit() {
        const data = {
            "QuestionId": props.question.id,
            "Answer": answer.map(e => e.toString()),
        };

        var submitError = null;
        transition('.btn-submit', "Submitting...",
            async () => {
                await hub.invoke("Answer", getSessionId(), data)
                    .catch(async (error) => {
                        var res = await quizApi.answer(data);
                        if (!res.success)
                            throw new Error(res.error);
                    })
                    .catch((error) => {
                        submitError = "Failed to submit answer...";
                    });
            },
            () => {
                setError(submitError);
            });
    }

    var buttonContent = null;

    if (props.answer) {
        buttonContent = <span className={styles.submitted_text}>Submitted!</span>
    }
    else {
        buttonContent = <>
            <Button className="btn-submit mt-5" onClick={submit}>Submit</Button>
            {error ? <span className="error">{error}</span> : null}
        </>
    }


    return (
        <div className={styles.container}>
            <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
                <div className={`${styles.map_input} ${fullscreen ? styles.full_screen : null}`}>

                    <button type="button" className={`${styles.btn_full_screen} gm-control-active gm-fullscreen-control`}
                        onClick={() => setFullScreen(!fullscreen)}>
                        <span class={styles.btn_full_icon}>{fullscreen ? 'fullscreen_exit' : 'fullscreen'}</span>
                    </button>

                    <Map
                        style={{ minHeight: '20rem' }}
                        zoom={3}
                        center={{ lat: 53.4659131, lng: -1.5707844 }}
                        gestureHandling={'greedy'}
                        fullscreenControl={false}
                        onClick={(e) => !props.answer ? setAnswer([e.detail.latLng.lat, e.detail.latLng.lng]) : null}
                        on
                        disableDefaultUI={false}
                        streetViewControl={false}
                        clickableIcons={false}
                        mapTypeControl={false}
                        styles={mapStyles}>
                        {answer ? <Marker position={{ lat: answer[0], lng: answer[1] }} /> : null}
                    </Map>
                </div>
            </APIProvider>

            {buttonContent}
            {transitionContent}
        </div>
    )
}
