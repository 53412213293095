import React from "react";
import styles from './Breadcrumbs.module.css';

export function Breadcrumbs(props) {
    var circles = []

    for (let index = 0; index < props.data.roundQuestionsCount; index++) {
        var state = '';
        if (props.data.question.number > index + 1) {
            state = styles.previous;
        }
        else if (props.data.question.number === index + 1) {
            state = styles.current;
        }

        circles.push(
            <div key={index} className={`${styles.circle} ${state}`} />
        );
    }

    return (
        <div className={styles.breadcrumbs}>
            <span className={styles.round_num}>Round {props.data.question.round} <span className={styles.total}>/ {props.data.totalRoundCount}</span></span>
            <span className={styles.question_num}>Question {props.data.question.number} <span className={styles.total}>/ {props.data.roundQuestionsCount}</span></span>

            <div className={styles.circles}>
                {circles}
            </div>
        </div>
    );
}