import React, {useState, useContext} from "react";
import HubContext from "utils/hubProvider";
import { Button, Media } from "components";
import { getSessionId } from "utils/helper";
import { useButtonTransition } from "hooks/useButtonTransition";
import quizApi from "services/QuizApi";
import styles from './Inputs.module.css';

export function MultipleChoice(props){
    const [selected, setSelected] = useState(props.answer != null ? props.question.options.indexOf(props.answer.answer[0]) : null);
    const hub = useContext(HubContext);
    const [error, setError] = useState(null);
    const { transition, transitionContent } = useButtonTransition() 
    const submitRef = React.createRef()

    async function submit() {
        const data = {
            "QuestionId" : props.question.id,
            "Answer" : [props.question.options[selected]],
        };

        var submitError = null;
        transition('.btn-submit', "Submitting...", 
            async () => {
                await hub.invoke("Answer", getSessionId(), data)
                    .catch(async (error) => {
                        console.log("Catch 1");
                        console.log(error);
                        var res = await quizApi.answer(data);
                        if(!res.success)
                            throw new Error(res.error);
                    })
                    .catch((error) => {
                        console.log("Catch 2");
                        console.log(error);
                        submitError = "Failed to submit answer...";
                    });
            },
            () => {
                setError(submitError);
            });
    }

    function selectItem(index) {
        setSelected(index);
        submitRef.current?.scrollIntoView({ behavior: 'smooth' })
    }


    var buttonContent = null;

    if(props.answer){
        buttonContent = <span className={styles.submitted_text}>Submitted!</span>
    }
    else {
        buttonContent = <>
            <Button buttonRef={submitRef} disabled={selected == null} className="btn-submit mt-5" onClick={submit}>Submit</Button>
            {error ? <span className="error">{error}</span> : null }
        </>
    }


    return (
        <div className={styles.container}>
            <Media media={props.media}/>

            {props.question.options.map((option, index) => (
                <button disabled={props.answer} key={index} className={`${styles.multiple_choice} ${selected === index ? styles.selected : ''}`} onClick={() => selectItem(index)}>{option}</button>
            ))}

            {buttonContent}
            {transitionContent}
        </div>
    )
}
