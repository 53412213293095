import React, { useState, useRef, useEffect } from "react";
import { ReactSketchCanvas } from "react-sketch-canvas";

import { Button } from "components";
import styles from './Join.module.css';

export function JoinPicture(props) {
    const [selectedColour, setSelectedColour] = useState('#000');
    const [error, setError] = useState(null);
    const canvasRef = useRef();


    useEffect(() => {
        setSelectedColour(props.colours[0])
    }, [props.colours])

    useEffect(() => {
        console.log("reset canvas");
        canvasRef.current.resetCanvas();
    }, [canvasRef])


    async function submitPicture() {
        try {
            setError(null)
            var image = await canvasRef.current.exportSvg();
            props.setPicture(image);
        } catch (error) {
            setError("Failed to submit picture, try again");
        }
    }

    return (
        <div className={`${styles.container} ${styles.no_padding}`}>
            <div className={styles.join_name}>{props.name}</div>

            <div className={styles.picture_container}>
                <div className={styles.picture_input_group}>
                    <p className={styles.input_text}>Draw a picture of yourself</p>
                    <div className={styles.canvas_container}>
                        <div className={styles.canvas}>
                            <ReactSketchCanvas
                                className={styles.canvas_box}
                                ref={canvasRef}
                                style={{
                                    border: "2px solid var(--color-black)",
                                    borderRadius: "1rem",
                                    aspectRatio: 1,
                                    // height: "auto",
                                    // width: "100%",
                                    overflow: "hidden",
                                }}
                                // canvasColor="#384047"
                                canvasColor='transparent'
                                strokeWidth={10}
                                strokeColor={selectedColour} />
                        </div>
                    </div>
                    <div className={styles.canvas_row}>
                        <div className={styles.canvas_buttons}>
                            <Button noShadow icon onClick={() => canvasRef.current.undo()}>undo</Button>
                            <Button noShadow icon onClick={() => canvasRef.current.redo()}>redo</Button>
                        </div>

                        <div className={styles.canvas_colours}>
                            {props.colours ? props.colours.map((colour, index) => {
                                var selected = selectedColour === colour;

                                return <button key={index} className={`btn ${styles.colour} ${selected ? styles.selected : ''}`} style={{ background: colour }} onClick={() => setSelectedColour(colour)} />
                            }) : null}
                        </div>
                    </div>
                </div>

                <div className={styles.picture_button_group}>
                    {error ? <p className={styles.join_error}>{error}</p> : null}
                    <Button className="btn-submit" onClick={submitPicture}>Let's Go</Button>
                </div>
            </div>
        </div>
    )

}