import React, {useState} from "react";
import { Button } from "components";


export function AdminBonuses(props){
    const [bonuses, setBonuses] = useState(props.data.bonuses);

    function updateUser(user, add) { 
        var newBonuses = [...bonuses];
        var index = newBonuses.findIndex(e => e.userId === user.id);
      
        if(index === -1){
          var newBonus = {
            userId: user.id,
            score: add ? 1 : -1,
          }
          newBonuses.push(newBonus);
        }
        else {
          var bonus = newBonuses[index];
          if(add)
            bonus.score += 1;
          else
            bonus.score -= 1;
        }
      
        setBonuses(newBonuses);
      }

    return(
        <div className="admin-content admin-bonuses p-3">
           
            <div className="admin-header px-3">
                <div className="bonus-header">
                    <span className="question-num">Bonuses</span>
                    <div className="bonus-header">
                    <Button small icon onClick={() => props.saveBonuses(bonuses)}>save</Button>
                    <Button small icon onClick={() => props.hideBonuses()}>arrow_back</Button>
                    </div>
                </div>

                <div className="seperator"/>
            </div>      

            <div className='bonus-users'>
                {props.data.players.map((user, index) => {
                    var bonus = bonuses.find(e => e.userId === user.id);
                    var score = bonus?.score ?? 0;

                    return (
                        <div key={index} className='bonus-user'>
                            <div className='bonus-user-text'>
                                <p className="name">{user.name}</p>
                                <p className={score < 0 ? 'negative' : score > 0 ? 'positive' : ''}>{score}</p>
                            </div>
                            <Button small icon onClick={() => updateUser(user, false)}><span className="material-symbols-outlined">remove</span></Button>
                            <Button small icon onClick={() => updateUser(user, true)}><span className="material-symbols-outlined">add</span></Button>
                        </div>
                    );
                })}
            </div>
        </div>
      )

}


