import React, { useState, useContext, useEffect, useRef } from "react";
import HubContext from "utils/hubProvider";
import { Button, Media, TextInput } from "components";
import { getSessionId } from "utils/helper";
import { useButtonTransition } from "hooks/useButtonTransition";
import quizApi from "services/QuizApi";
import styles from './Inputs.module.css';

export function ConfirmInput(props) {
    const [answer, setAnswer] = useState(props.answer != null ? props.answer.answer[0] : null);
    const [step, setStep] = useState(props.step);

    const hub = useContext(HubContext);
    const [error, setError] = useState(null);
    const { transition, transitionContent } = useButtonTransition()
    const submitRef = React.createRef()

    //Setup Hub
    useEffect(() => {
        hub.off('step-changed');
        hub.on('step-changed', (step) => setStep(step));
    }, [hub])

    async function submit() {
        const data = {
            "QuestionId" : props.question.id,
            "Answer" : [answer],
            "ExtraDetails" : { "Step" : step.toString() }
        };

        var submitError = null;
        transition('.btn-submit', "Submitting...", 
            async () => {
                await hub.invoke("Answer", getSessionId(), data)
                    .catch(async (error) => {
                        var res = await quizApi.answer(data);
                        if(!res.success)
                            throw new Error(res.error);
                    })
                    .catch((error) => {
                        submitError = "Failed to submit answer...";
                    });
            },
            () => {
                setError(submitError);
            });
    }

    function scrollToButton() {
        submitRef.current?.scrollIntoView({ behavior: 'smooth' })
    }


    var buttonContent = null;

    if(props.answer){
        buttonContent = <span className={styles.submitted_text}>Submitted!</span>
    }
    else {
        buttonContent = <>
            <Button buttonRef={submitRef} className="btn-submit mt-5" onClick={submit}>Submit</Button>
            {error ? <span className="error">{error}</span> : null }
        </>
    }

    return (
        <div className={styles.container}>

            <div className={styles.choice_group}>
                {props.question.options.slice(0, step + 1).map((option, index) => {
                    var altClass = index % 2 !== 0 ? styles.alt : null

                    return <p key={index} className={altClass}>{option}</p>
                })}
            </div>

            <Media media={props.media} />

            <TextInput className={styles.text_input} placeholder={"Answer"} value={answer} onLostFocus={() => scrollToButton()}
                onChange={(event) => setAnswer(event.target.value)} disabled={props.answer} />

            {buttonContent}
            {transitionContent}
        </div>
    )
}
