export const apiSuccessResult = (data) => {
    return  {
        data: data,
        error: null,
        success: true,
    }
}

export const apiErrorResult = (error) => {

    var errorMessage = null;

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        errorMessage = error.response.data;
    } else if (error.request) {
        errorMessage = error.request;
    } else {
        // Something happened in setting up the request that triggered an Error
        errorMessage = error.message;
    }

    return  {
        data: null,
        error: errorMessage,
        success: false,
    }
}
