import React, { useState, useEffect, useContext } from "react";
import HubContext from "utils/hubProvider";
import { useAudioPlayer } from 'react-use-audio-player';
import { MediaType } from "enumerations/MediaType";
import { ViewerWithMedia } from "../ViewWithMedia";
import { ViewWithoutMedia } from "../ViewWithoutMedia";
import VolumeContext from "utils/volumeProvider";

export function QuestionView(props) {
    const [answers, setAnswers] = useState(props.data.playerAnswers)
    const [showCorrectPlayers, setShowCorrectPlayers] = useState(false)
    const hub = useContext(HubContext);
    const player = useAudioPlayer();
    const volume = useContext(VolumeContext);

    const fadeTimeout = React.useRef(null);

    useEffect(() => {
        if (props.data.media?.mediaType === MediaType.Audio) {
            var media = `/media/${props.data.media.path}`;
            player.load(media, { initialVolume: volume.music });
        }

        return function cleanup() {
            player.cleanup();
        };
    }, []);

    //Setup Hub
    useEffect(() => {
        hub.off('answered');
        hub.on('answered', (userAnswers) => setAnswers(userAnswers));

        hub.off('play');
        hub.on('play', play);

        hub.off('pause');
        hub.on('pause', stop);

        hub.off('timer-start');
        hub.on('timer-start', play);

        hub.off('timer-stop');
        hub.on('timer-stop', stop);
    }, [hub])

    useEffect(() => {
        setShowCorrectPlayers(false);
    }, [props.answer])

    function OnAnswerShown() {
        setShowCorrectPlayers(true);
    }

    function play() {
        clearTimeout(fadeTimeout.current);
        player.stop();
        player.setVolume(volume.music);
        player.play();

        if (props.data.media?.extraDetails?.Fade) {
            var fadeInt = parseInt(props.data.media?.extraDetails?.Fade)
            fadeTimeout.current = setTimeout(() => player.fade(1, 0, 1000), fadeInt * 1000);
        }
    }

    function stop() {
        player.stop();
    }

    if (props.data.media) {
        return <ViewerWithMedia {...props} players={props.data.players} answers={answers} showCorrectPlayers={showCorrectPlayers}
            onAnswerShown={OnAnswerShown} playing={player.playing} />
    }
    else {
        return <ViewWithoutMedia {...props} players={props.data.players} answers={answers} showCorrectPlayers={showCorrectPlayers} onAnswerShown={OnAnswerShown} />
    }
}