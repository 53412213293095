import { useState, useEffect } from 'react';

const useComponentVisible = (scrollClass, componentClass) => {
    const [visible, setVisible] = useState(null);

    useEffect(() => {
        const handleScroll = (event) => {
            console.log('Scroll');
            updateVisible();
        };

        const updateVisible = () => {
            var elm = document.querySelector(componentClass);
            if (elm == null) {
                setVisible(null);
                return;
            }

            var rect = elm.getBoundingClientRect();
            var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight) - (rect.height * 0.2);
            var val = !(rect.bottom < 0 || rect.top - viewHeight >= 0);
            setVisible(val);
        }    

        var container = document.querySelector(scrollClass);
        container.addEventListener('scroll', handleScroll);

        let observer = new MutationObserver((mutations) => {
            mutations.forEach((mutation) => {
              let oldValue = mutation.oldValue;
              let newValue = mutation.target.textContent;
              if (oldValue !== newValue) {
                  // do something
                  console.log("CHANGE");
                  updateVisible();
              }
            });
          });
          
        observer.observe(document.body, {
            characterDataOldValue: true, 
            subtree: true, 
            childList: true, 
            characterData: true
        });

        updateVisible(container);
        // Clean up the event listener when the component unmounts
        return () => {
            container.removeEventListener('scroll', handleScroll);
            observer.disconnect();
        };
    }, []);

    return visible;
};

export default useComponentVisible;