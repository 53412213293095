import React from "react";
import styles from './PlayerImage.module.css';

export function PlayerImage(props) {

  var customClass = props.className ?? '';

  if (props.player == null)
    return <div className={`${styles.player} ${styles.empty}`}></div>

  var playerContent = null;
  if (props.player.image) {
    playerContent = <div className={styles.image_container}><img src={`data:image/svg+xml;utf8,${encodeURIComponent(props.player.image)}`} alt='User' /></div>
  }
  else if (props.player.image == null) {
    playerContent = <div className={styles.no_image} />
  }
  else {
    playerContent = <div className={styles.blank_image} />
  }

  return (
    <div className={`${styles.player} ${customClass}`}>
      {playerContent}
      {!props.withoutName ? <p>{props.player.name}</p> : null}
    </div>
  );
}