import {useState} from 'react'; 
import { useSpring, animated } from '@react-spring/web'
import styles from './useButtonTransition.module.css'
import { delay } from 'utils/helper';

export const useButtonTransition = () => { 
    const [text, setText] = useState("");
    const [running, setRunning] = useState(false);
    const [springs, api] = useSpring(() => ({
        from: { left: 0, top: 0, width: '100%', height: '100%', opacity: 0, padding: 0, borderRadius: '0rem' },
      }))

    var isOpenTransitionComplete = false;
    var isProccessComplete = false;
    var shouldCloseTransition = true;
    var onTransitionOpenCallback = null;

    const transitionContent = running ? (
            <animated.div
                className={styles.btn}
                style={{
                    position: 'absolute',
                    overflow: 'hidden',
                    pointerEvents: 'none',
                    zIndex: 99,
                    ...springs,            
                }}>
                {text}
            </animated.div>
        ) : null;
    
    async function transition(anchorClass, text, onLoading, onTransitionOpen = null, shouldClose = true) {
        await delay(200);
       
        shouldCloseTransition = shouldClose;
        onTransitionOpenCallback = onTransitionOpen;
        setRunning(true);
        setText(text);
        isOpenTransitionComplete = false;
        isProccessComplete = false;

        const container = document.getElementById('root'); 
        const anchor = document.querySelector(anchorClass); 

        if (container && anchor) { 
            var anchorBounds = anchor != null ? anchor.getBoundingClientRect() : null
            var containerBounds = container.getBoundingClientRect();
      
            processLoadingCallback(onLoading);

            api.start({
                from: {
                    left: anchorBounds.left,
                    top: anchorBounds.top,
                    width: anchorBounds.width,
                    height: anchorBounds.height,
                    opacity: 1,
                    borderRadius: '3rem',
                },
                to: [
                    {
                        left: containerBounds.left,
                        top: anchorBounds.top,
                        width: containerBounds.width,
                        height: anchorBounds.height,
                        opacity: 1,
                        borderRadius: '1rem',
                    },
                    {
                        left: containerBounds.left,
                        top: containerBounds.top,
                        width: containerBounds.width,
                        height: containerBounds.height,
                        borderRadius: '0rem',
                        opacity: 1
                    },
                ],
                onRest: (e) => {
                    if (e.finished === true) {
                        setTimeout(function() {
                            isOpenTransitionComplete = true;
                            close();
                        }, 1000);
                    }
                }
            });
        }
    }

    const processLoadingCallback = (loadingCallback) => {
        loadingCallback().then(() => {
            isProccessComplete = true;
            close();
        })
    }


    const close = () => {
        if(!isOpenTransitionComplete || !isProccessComplete)
            return;

        if(onTransitionOpenCallback) onTransitionOpenCallback();

        if(!shouldCloseTransition) return;

        const container = document.getElementById('root'); 

        if (container) { 
            var containerBounds = container.getBoundingClientRect();


            api.start({
                from: {
                    left: containerBounds.left,
                    top: containerBounds.top,
                    width: containerBounds.width,
                    height: containerBounds.height,
                },
                to: [
                    {
                        left: containerBounds.left,
                        top: -containerBounds.height,
                        width: containerBounds.width,
                        opacity: 1,
                    },
                    {
                        opacity: 0,
                    },
                    {
                        width: 0,
                    },
                ],
                onRest: (e) => {
                    if (e.finished === true) {
                        setRunning(false);
                    }
                },
            })
        }
    }

    return { 
        transition,
        transitionContent, 
    }; 
}