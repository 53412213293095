import React, {useEffect, useContext, useState} from "react";
import { MediaType } from "enumerations/MediaType";
import styles from './Media.module.css';
import HubContext from "utils/hubProvider";

export function Media(props){
    if(props.media == null)
        return null;

    if(props.media.mediaType === MediaType.Image)
        return <div className={styles.media_image}><img src={`/media/${props.media.path}`} className="img-fluid" alt="Question"/></div>

    if(props.media.mediaType === MediaType.Audio)
        return <div className={styles.media_image}><img src={props.isRunning ? '/media/record-playing.svg' : '/media/record.svg'} className={`img-fluid ${styles.sound_image}`} alt="SoundWave"/></div>


    return null;
}