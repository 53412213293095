import './Validator.css'

import React, { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { ConnectionError, FullScreen } from "components";
import { HubProvider } from 'utils/hubProvider'

import adminApi from "services/AdminApi";
import { ValidatorContent } from './ValidatorContent';

export function Validator() {
    const [hubConnection, setHubConnection] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [connectionFailed, setConnectionFailed] = useState(false);

    const connectingRef = React.useRef(null);
    const contentRef = React.useRef(null);

    //Connect
    useEffect(() => {
        setIsLoading(true)
        async function init() {
            await connect();

            var adminData = await adminApi.adminData();
            setData(adminData.data);

            setIsLoading(false)
        }
        init();
    }, [])

    async function connect() {
        setConnectionFailed(false);
        setIsReconnecting(false);

        var conn = new HubConnectionBuilder()
            .withUrl(`hub/quiz-hub`)
            .withAutomaticReconnect()
            .build();

        conn.onreconnected(() => {
            setConnectionFailed(false);
            setIsReconnecting(false);
        })

        conn.onreconnecting((error) => {
            if (conn.state === HubConnectionState.Reconnecting) {
                setIsReconnecting(true);
            }
        });

        conn.onclose(async () => {
            await connect();
        });

        try {
            await conn.start();
            console.log('Connection started!');
            setHubConnection(conn);
        } catch (error) {
            console.log('Failed to start!');
            setConnectionFailed(true);
        }
    }

    async function reconnect() {
        setIsLoading(true)

        connect()

        setIsLoading(false)
    }


    var content = null;
    var key = "";
    var ref = null;

    if (isLoading) {
        content = <FullScreen text="Connecting..." />
        key = "Connecting";
        ref = connectingRef;
    }
    else {
        content = <ValidatorContent data={data} />
        key = "Content";
        ref = contentRef;
    }

    return (
        <div className='validator-container'>
            <ConnectionError isReconnecting={isReconnecting} connectionFailed={connectionFailed} reconnect={reconnect} />
            <div className='admin-content-container'>
                <HubProvider value={hubConnection}>
                    <SwitchTransition mode='in-out'>
                        <CSSTransition
                            timeout={{
                                enter: 0,
                                exit: 500,
                            }}
                            key={key}
                            nodeRef={ref}
                            classNames="slide">
                            <div ref={ref} className="slide-container">{content}</div>
                        </CSSTransition>
                    </SwitchTransition>
                </HubProvider>
            </div>
        </div>

    )
}