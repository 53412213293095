import React, { useContext, useState, useEffect } from "react";
import HubContext from "utils/hubProvider";
import { Progress } from "components";
// import { PlayerInput } from "./PlayerInput";
import { getSessionId } from "utils/helper";
import styles from './Styles/Question.module.css';

import { MultipleChoice } from "./Inputs/MultipleChoice";
import { QuestionType } from "enumerations/QuestionType";
import { ManualInput } from "./Inputs/ManualInput";
import { DateInput } from "./Inputs/DateInput";
import { TimeToSingInput } from "./Inputs/TimeToSingInput";
import { MapInput } from "./Inputs/MapInput";
import { ConfirmInput } from "./Inputs/ConfirmInput";
import { Countdown } from "components/Countdown/Countdown";
import useCanScroll from "hooks/useCanScroll";
import useComponentVisible from "hooks/useComponentVisible";


export function Question(props) {
    const hub = useContext(HubContext);
    const [userAnswers, setUserAnswers] = useState(props.data.playerAnswers);
    const buttonVisible = useComponentVisible(`.${styles.container}`,'.btn-submit');

    //Setup Hub
    useEffect(() => {
        hub.off('answered');
        hub.on('answered', (userAnswers) => setUserAnswers(userAnswers));
    }, [hub])


    var withProgress = props.data.question.type !== QuestionType.TimeToSing;
    // var withCountdown = props.data.question.type === QuestionType.ConfirmChoice;
    return (
        <div className={styles.container}>
            {withProgress ?
                <Progress progress={userAnswers.length} total={props.data.players.length} label="Players Answered" icon="account_circle" />
                : null}
            {/* {withCountdown ? <Countdown icon="timer" /> : null} */}

            <div className={`${styles.question} ${withProgress ? styles.with_progress : ""}`}>
                <span className={styles.round_num}>Round {props.data.question.round} <span className={styles.total}>/ {props.data.totalRoundCount}</span></span>
                <span className={styles.question_num}>Question {props.data.question.number} <span className={styles.total}>/ {props.data.roundQuestionsCount}</span></span>

                <div className="seperator" />

                <span className={styles.question_text}>{props.data.question.question}</span>
            </div>

            <PlayerInput question={props.data.question} answer={userAnswers.find(e => e.userId === getSessionId())}
                media={props.data.media} step={props.data.step} />

            {buttonVisible === false ? <div className={styles.scroll_arrow}>keyboard_double_arrow_down</div> : null}

        </div>
    )
}

function PlayerInput(props) {

    switch (props.question.type) {
        case QuestionType.MultipleChoice:
            return <MultipleChoice {...props} />
        case QuestionType.Text:
            return <ManualInput {...props} />
        case QuestionType.Date:
            return <DateInput {...props} />
        case QuestionType.TimeToSing:
            return <TimeToSingInput {...props} />
        case QuestionType.Map:
            return <MapInput {...props} />
        case QuestionType.ConfirmChoice:
            return <ConfirmInput {...props} />
        default:
            return null;
    }
}
