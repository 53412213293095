import styles from '../Styles/QuestionView.module.css';
import { useAudioPlayer } from 'react-use-audio-player';
import React, { useState, useEffect, useContext } from "react";
import { delay } from 'utils/helper';

import ding from 'assets/media/correct.mp3';
import bloop from 'assets/media/bloop.mp3';
import VolumeContext from 'utils/volumeProvider';
import { Textfit } from 'react-textfit';

export function Text(props) {
    const player = useAudioPlayer();
    const [showAnswer, setShowAnswer] = useState(false)
    const [hideOption, setHideOption] = useState(false)
    const volume = useContext(VolumeContext);

    //On Answer
    useEffect(() => {
        player.load(bloop, { initialVolume: volume.sfx });

        setShowAnswer(false);
        setHideOption(false);

        if (!props.answerView)
            return;

        async function animate() {
            await delay(1000);
            player.play();
            await delay(500);
            player.play();
            await delay(500);
            player.play();
            setHideOption(true);
            await delay(500);
            player.play();
            await delay(500);

            player.load(ding, { autoplay: true, initialVolume: volume.sfx });
            setShowAnswer(true);
            props.onAnswerShown();

        }

        animate();

    }, [props.answerView]);


    return (
        <>
            <Textfit mode="multi" className={styles.question_text} max={48}>
                {props.data.question.question}
            </Textfit>
            <div className={`${styles.text_question_type} ${props.data.media ? styles.with_media : ""}`}>
                {showAnswer ?
                    <div className={styles.answers}>
                        {props.data.answer.answer.map((answer, index) =>
                            <p key={index}>{answer}</p>
                        )}
                    </div>
                    : <p className={hideOption ? styles.hide_option : ""}>Type an answer on your device</p>
                }
            </div>
        </>
    )
}