import axios from 'axios';
import { apiErrorResult, apiSuccessResult } from './ApiResult';

const validatorApi = { 

    validate: async (data) => { 
        const URL = `api/validator/validate`; 
        return await axios.post(URL, data) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    mention: async (data) => { 
        const URL = `api/validator/mention`; 
        return await axios.post(URL, data) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    } 
} 
export default validatorApi; 