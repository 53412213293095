import React from "react";
import styles from './FullScreen.module.css';
import logo from 'assets/images/quiz-logo.png';

export function FullScreen(props){
  return (
    <div className={styles.container} >
        {props.text === "" ? <img src={logo} alt="Logo" /> : <p>{props.text}</p>}
    </div>
  )
}