import React from "react";
import styles from './PlayerAnswers.module.css';
import { PlayerImage } from "components";

export function PlayerAnswers(props) {
    return (
        <div className={styles.players}>
            {props.players.map((player, index) => {
                var answered = props.answers.filter(e => e.userId === player.id);
                var score = answered.length > 0 ? answered[0].score : null;
                var answeredStyle = (!props.showCorrectPlayers || score) && answered.length > 0 ? styles.answered : '';

                return (
                    <div key={index} className={styles.player_answer}>
                        <div className={`${styles.player_container} ${answeredStyle}`}>
                            <PlayerImage className={styles.player_image} player={player} />
                            {props.showCorrectPlayers && score ? <span className={styles.score}>{score ? `+${score}` : null}</span> : null}
                        </div>
                    </div>
                )

            }
            )}
        </div>
    );

}