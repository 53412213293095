import styles from './Join.module.css';

import React, { useState } from "react";
import { useButtonTransition } from "hooks/useButtonTransition";
import userApi from 'services/UserApi'
import { JoinState } from "enumerations/PlayerState";
import { JoinName } from "./JoinName";
import { JoinPicture } from "./JoinPicture";
import { delay } from 'utils/helper';

export function Join(props) {
    const { transition, transitionContent } = useButtonTransition()
    const [user, setUser] = useState(props.user);
    const [viewState, setViewState] = useState(props.user == null ? JoinState.Join : JoinState.JoinPicture);

    async function join(name) {
        setUser({ name: name });

        transition('.btn-submit', "Joining...",
            async () => {
                console.log("Loading callback")
                await props.connect();

                var result = await userApi.join(name);
                setUser(result.data);
            },
            () => {
                setViewState(JoinState.JoinPicture)
            });
    }

    async function setPicture(image) {
        transition('.btn-submit', "Joining...",
            async () => {
                await userApi.setPicture(image);
            },
            () => {
                var newUser = {
                    ...user, // Copy the old fields
                    image: image // But override this one
                }
                setUser(newUser);
                props.joined(newUser);
            }, false);
    }

    // async function submitPicture(canvas) {
    //     await delay(2000);
    //     console.log(canvas);
    //     image = await canvas.exportSvg();
    //     await userApi.setPicture(image);
    // }

    const renderContent = () => {
        switch (viewState) {
            case JoinState.Join:
                return <JoinName join={join} />
            case JoinState.JoinPicture:
                return <JoinPicture name={user.name} setPicture={setPicture} colours={user.colours} />
            default:
                return null;
        }
    }

    return (
        <div className={styles.join}>
            {renderContent()}
            {transitionContent}
        </div>
    )

}