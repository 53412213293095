import axios from 'axios';
import { getSessionId } from '../utils/helper';
import { apiErrorResult, apiSuccessResult } from './ApiResult';

const userApi = { 
    verify: async () => { 
        const URL = `api/user/verify/${getSessionId()}`; 
        return await axios.get(URL) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    }, 

    join: async (name) => { 
        const data = {
            Id: getSessionId(),
            Name: name
        };

        const URL = `api/user/join`; 
        return await axios.post(URL, data) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    setPicture: async (image) => { 
        const data = {
            Id: getSessionId(),
            Image: image,
        };

        const URL = `api/user/picture`; 
        return await axios.post(URL, data) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    } 
} 
export default userApi; 