import { Textfit } from 'react-textfit';
import styles from '../Styles/QuestionView.module.css';
import React, { useState, useEffect } from "react";

export function TimeToSing(props) {


    return (
        <>
            <Textfit mode="multi" className={styles.question_text} max={48}>
                {props.data.question.question}
            </Textfit>
            <div className={`${styles.text_question_type} ${props.data.media ? styles.with_media : ""}`}>
                <p>A short clip of the song will be played, then fade out. </p><br /><p>Press stop on your device when you think the singing would have started</p>
            </div>

        </>
    )
}