import { Button } from "components";
import { QuestionType } from "enumerations/QuestionType";

export function AdminButtons(props) {
    var buttons = null;

    switch (props.data.question.type) {
        case QuestionType.TimeToSing:
            buttons = <>
                <Button small icon onClick={() => props.timer(true)}>timer_play</Button>
                <Button small icon onClick={() => props.timer(false)}>timer_pause</Button>
            </>
            break;
        case QuestionType.Map:
            buttons = <>
                <Button small icon onClick={() => props.zoom(true)}>zoom_in</Button>
                <Button small icon onClick={() => props.zoom(false)}>zoom_out</Button>
                <Button small icon onClick={() => props.zoom(null)}>location_on</Button>
            </>
            break;
        // case QuestionType.ConfirmChoice:
        //     buttons = <>
        //         <Button small icon onClick={() => props.countdown(true)}>play_arrow</Button>
        //         <Button small icon onClick={() => countdown(false)}>stop</Button>
        //     </>
        //     break;
        default:
            buttons = <>
                <Button small icon onClick={() => props.audio(true)}>play_arrow</Button>
                <Button small icon onClick={() => props.audio(false)}>stop</Button>
            </>;
    }

    return (<div className="admin-buttons">
        <Button small icon onClick={() => props.showBonuses()}>manage_accounts</Button>
        <Button small icon onClick={() => props.viewScores()}>leaderboard</Button>

        {buttons}
    </div>
    );
}