export const getSessionId = () => {
    var sessionId = localStorage.getItem('sessionId');
    
    if (!sessionId)
    {
        sessionId = Date.now();
        localStorage.setItem('sessionId', sessionId);
    }
    
    return sessionId;
}

export const randomNumberInRange = (min, max) => {
    return Math.floor(Math.random()
        * (max - min + 1)) + min;
};

export const delay = ms => new Promise(res => setTimeout(res, ms))


export const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );


// export const invokeAndRetry = (hub,) => {
//     var sessionId = localStorage.getItem('sessionId');
    
//     if (!sessionId)
//     {
//         sessionId = Date.now();
//         localStorage.setItem('sessionId', sessionId);
//     }
    
//     return sessionId;
// }
