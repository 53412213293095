// import { Home } from "./components/Player/Home";
import { Viewer } from "views/Viewer/Viewer";
import { Admin } from "views/Admin/Admin";
import { Player } from "views/Player/Player";
import { Validator } from "views/Validator/Validator";

const AppRoutes = [
  {
    index: true,
    element: <Player />
  },
  {
    path: '/admin',
    element: <Admin />
  },
  {
    path: '/viewer',
    element: <Viewer/>
  },
  {
    path: '/validator',
    element: <Validator/>
  }
];

export default AppRoutes;
