import './Admin.css'

import React, { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';
import { ConnectionError, FullScreen } from "components";
import { HubProvider } from 'utils/hubProvider'

import adminApi from "services/AdminApi";
import { AdminContent } from "./AdminContent";
import { AdminBonuses } from './AdminBonuses';

export function Admin() {
    const [hubConnection, setHubConnection] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [isReconnecting, setIsReconnecting] = useState(false);
    const [connectionFailed, setConnectionFailed] = useState(false);
    const [showBonuses, setShowBonuses] = useState(false);
    const [transition, setTransition] = useState(false);

    const connectingRef = React.useRef(null);
    const contentRef = React.useRef(null);
    const bonusesRef = React.useRef(null);
    const incomingRef = React.useRef(null);

    //Connect
    useEffect(() => {
        setIsLoading(true)
        async function init() {
            await connect();

            var adminData = await adminApi.adminData();
            setData(adminData.data);

            setIsLoading(false)
        }
        init();
    }, [])

    async function connect() {
        setConnectionFailed(false);
        setIsReconnecting(false);

        var conn = new HubConnectionBuilder()
            .withUrl(`hub/quiz-hub`)
            .withAutomaticReconnect()
            .build();

        conn.onreconnected(() => {
            setConnectionFailed(false);
            setIsReconnecting(false);
        })

        conn.onreconnecting((error) => {
            if (conn.state === HubConnectionState.Reconnecting) {
                setIsReconnecting(true);
            }
        });

        conn.onclose(async () => {
            await connect();
        });

        try {
            await conn.start();
            console.log('Connection started!');
            setHubConnection(conn);

            var adminData = await adminApi.adminData();
            setData(adminData.data);
        } catch (error) {
            console.log('Failed to start!');
            setConnectionFailed(true);
        }
    }

    async function reconnect() {
        setIsLoading(true)

        connect()

        setIsLoading(false)
    }

    async function showHideBonuses(visible) {
        setTransition(true);
        setShowBonuses(visible);

        setTimeout(function () {
            setTransition(false);
        }, 800);
    }

    async function saveBonuses(bonuses) {
        setTransition(true);

        await adminApi.saveBonuses(bonuses);

        var adminData = await adminApi.adminData();
        setData(adminData.data);

        setShowBonuses(false);

        setTimeout(function () {
            setTransition(false);
        }, 800);
    }


    var content = null;
    var key = "";
    var ref = null;

    if (isLoading) {
        content = <FullScreen text="Connecting..." />
        key = "Connecting";
        ref = connectingRef;
    }
    else if (showBonuses) {
        content = <AdminBonuses data={data} hideBonuses={() => showHideBonuses(false)} saveBonuses={saveBonuses} />
        key = "Bonuses";
        ref = bonusesRef;
    }
    else {
        content = <AdminContent data={data} showBonuses={() => showHideBonuses(true)} />
        key = "Content";
        ref = contentRef;
    }

    if (transition) {
        content = <FullScreen text="" />
        key = "Incoming";
        ref = incomingRef
    }

    return (
        <div className='admin-container'>
            <ConnectionError isReconnecting={isReconnecting} connectionFailed={connectionFailed} reconnect={reconnect} />
            <div className='admin-content-container'>
                <HubProvider value={hubConnection}>
                    <SwitchTransition mode='in-out'>
                        <CSSTransition
                            timeout={{
                                enter: 0,
                                exit: 500,
                            }}
                            key={key}
                            nodeRef={ref}
                            classNames="slide">
                            <div ref={ref} className="slide-container">{content}</div>
                        </CSSTransition>
                    </SwitchTransition>
                </HubProvider>
            </div>
        </div>

    )
}