import React, {useState, useContext} from "react";
import HubContext from "utils/hubProvider";
import { Button, Media, TextInput } from "components";
import { getSessionId } from "utils/helper";
import { useButtonTransition } from "hooks/useButtonTransition";
import quizApi from "services/QuizApi";
import styles from './Inputs.module.css';

export function ManualInput(props){
    const [answers, setAnswers] = useState(props.answer != null ? props.answer.answer : Array(props.question.options.length).fill(''));
    const hub = useContext(HubContext);
    const [error, setError] = useState(null);
    const { transition, transitionContent } = useButtonTransition() 
    const submitRef = React.createRef()

    async function submit() {
        const data = {
            "QuestionId" : props.question.id,
            "Answer" : answers,
        };

        var submitError = null;
        transition('.btn-submit', "Submitting...", 
            async () => {
                await hub.invoke("Answer", getSessionId(), data)
                    .catch(async (error) => {
                        var res = await quizApi.answer(data);
                        if(!res.success)
                            throw new Error(res.error);
                    })
                    .catch((error) => {
                        submitError = "Failed to submit answer...";
                    });
            },
            () => {
                setError(submitError);
            });
    }

    function update(event, index) {
        let newArr = [...answers];
        newArr[index] = event.target.value; 
        setAnswers(newArr);
    }

    function scrollToButton() {
        submitRef.current?.scrollIntoView({ behavior: 'smooth' })
    }


    var buttonContent = null;

    if(props.answer){
        buttonContent = <span className={styles.submitted_text}>Submitted!</span>
    }
    else {
        buttonContent = <>
            <Button buttonRef={submitRef} className="btn-submit mt-5" onClick={submit}>Submit</Button>
            {error ? <span className="error">{error}</span> : null }
        </>
    }


    return (
        <div className={styles.container}>
            <Media media={props.media}/>

            {props.question.options.map((option, index) => (
                <TextInput className={styles.text_input} key={index} placeholder={option} value={answers[index]} onLostFocus={() => scrollToButton()}
                           onChange={(event) => update(event, index)} disabled={props.answer}/>
            ))}

            {buttonContent}
            {transitionContent}
        </div>
    )
}
