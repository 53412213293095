import React, { useEffect, useContext, useState } from "react";
import logo from 'assets/images/quiz-logo.png';
import HubContext from "utils/hubProvider";
import { useAudioPlayer } from 'react-use-audio-player';
import thud from 'assets/media/thud.wav';
import styles from './Styles/EndView.module.css';
import { PlayerImage } from "components";
import { Flipped, Flipper } from "react-flip-toolkit";
import quizApi from "services/QuizApi";
import { delay, groupBy } from "utils/helper";
import tada from 'assets/media/tada.mp3';
import drumrollLong from 'assets/media/drumroll_long.mp3';
import Confetti from 'react-confetti'
import useScreenSize from "hooks/useScreenSize";
import VolumeContext from "utils/volumeProvider";

export function EndView(props) {
    const [players, setPlayers] = useState(Array(props.data.players.length).fill(null));
    const [showConfetti, setShowConfetti] = useState(false);
    const audioPlayer = useAudioPlayer();
    const audioPlayerPop = useAudioPlayer();
    const [error, setError] = useState(false);
    const { width, height } = useScreenSize()
    const volume = useContext(VolumeContext);

    //Setup Hub
    useEffect(() => {
        async function init() {

            var newPlayers = Array(props.data.players.length).fill(null);
            setPlayers(newPlayers);
            var scores = await quizApi.scores();

            await delay(3000);

            if (!scores.success) {
                setError(true);
                return;
            }

            const groupedScores = groupBy(scores.data, "totalScore");

            // const sortedScores = Object.keys(groupedScores).sort((a, b) => b.key - a.key).reduce(
            //     (obj, key) => {
            //         obj[key] = groupedScores[key];
            //         return obj;
            //     },
            //     {}
            // );

            // var sortedScores = groupedScores.sort((a, b) => b.key - a.key);
            audioPlayer.load(drumrollLong, { autoplay: true, loop: true, initialVolume: volume.sfx });

            var delayVal = 5000 / scores.data.length;
            console.log(delayVal);

            var posIndex = props.data.players.length - 1;
            const keys = Object.keys(groupedScores).sort((a, b) => parseInt(a) - parseInt(b));
            for (let position = 0; position < keys.length; position++) {
                const sorted = groupedScores[keys[position]];

                for (let index = sorted.length - 1; index >= 0; index--) {
                    const score = sorted[index];
                    var player = props.data.players.find(e => e.id === score.userId);
                    player.score = score.totalScore;
                    player.position = keys.length - position - 1;

                    newPlayers[posIndex] = player;
                    setPlayers(players => [...newPlayers]);

                    if (position < keys.length - 1) {
                        if (posIndex < 3) {
                            audioPlayerPop.play(thud);
                            await delay(1500);
                        }
                        else
                            await delay(delayVal);

                    }
                    else if (posIndex === 0) {
                        setShowConfetti(true);
                        audioPlayerPop.load(tada, { autoplay: true, initialVolume: volume.sfx });
                    }


                    posIndex--;

                }
            }

            audioPlayer.fade(1, 0, 250);
        }

        audioPlayerPop.load(thud, {initialVolume: volume.sfx});
        setShowConfetti(false);
        init();
    }, [])


    var scores = [];

    for (let index = 0; index < 18; index++) {
        var player = players[index];
        if (player == null || player.score == null)
            scores.push(<div className={styles[`score${index + 1}`]} />)
        else {
            var color = player.position === 0 ? styles.gold : player.position === 1 ? styles.silver : player.position === 2 ? styles.bronze : null;
            var score = null;
            scores.push(
                <div className={styles[`score${index + 1}`]}>
                    <Flipped key={index} flipId={player.id}>

                        <div key={index} className={`${styles.player_score} ${score ? styles.has_score : ""} ${color}`}>
                            <div className={styles.position}>{player.position + 1}</div>
                            <div className={styles.player_container}>
                                <PlayerImage className={styles.player_image} player={player} withoutName={true} />
                            </div>
                            <div className={styles.player_content}>
                                <p className={styles.player_score_val}>
                                    {player.score}
                                </p>
                                <p className={styles.player_name}>{player.name}</p>
                            </div>
                            {score ? <span className={styles.score}>+{score}</span> : null}
                        </div>

                    </Flipped>
                </div>
            )
        }
    }

    return (
        <div className={styles.content_container}>
            {showConfetti ? <Confetti width={width} height={height} /> : null}

            <img className={styles.logo} src={logo} alt="Logo" />

            <div className={styles.container}>

                <div className={styles.left}>
                    <div className={styles.header} />
                </div>

                <div className={`${styles.right}`}>
                </div>
            </div>

            {error ?
                <div className={styles.error}>
                    <p>There was an error, refresh</p>
                </div> :
                <div className={styles.score_container} >
                    <div className={styles.player_scores}>
                        {scores}
                    </div>
                </div>
            }
        </div>
    )

}