import React from "react";
import styles from './TextInput.module.css';

export function TextInput(props){
  var className = `${styles.text_input}`;

  if(props.className)
    className += " " + props.className;

  return (
    <input className={className} placeholder={props.placeholder} value={props.value ?? ""} onChange={props.onChange} 
           inputMode={props.keyboard} onKeyDown={props.onKeyDown} type={props.type} onBlur={props.onLostFocus} disabled={props.disabled}/>
  )
}