import React, {useState, useContext, useEffect} from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";

import quizApi from "services/QuizApi";

import HubContext from "utils/hubProvider";

import { QuizState } from "enumerations/QuizState";

import { FullScreen } from "components";

import { AwaitingQuestion } from "./AwaitingQuestion";
import { AwaitingStart } from "./AwaitingStart";
import { Question } from "./Question";
import { Ended } from "./Ended";
import { Round } from './Round';

export function Game(props){
    const hub = useContext(HubContext);
    const [transition, setTransition] = useState([false, ""]);
    const [data, setData] = useState(props.data);

    const questionRef = React.useRef(null);
    const endedRef = React.useRef(null);
    const awaitingRef = React.useRef(null);
    const awaitingStartRef = React.useRef(null);
    const incomingRef = React.useRef(null);
    const roundRef = React.useRef(null);


    //Setup Hub
    useEffect(() => {
        hub.off('state-changed');
        hub.on('state-changed', async () => {
            var quizData = await quizApi.quizData();
            setData(quizData.data);    

            if(quizData.data.state === QuizState.Question){
                setTransition([true, "Incoming Question"]);

                setTimeout(function() {
                    setTransition([false, ""]);
                }, 3000);
            }
            else if(![QuizState.AwaitingStart, QuizState.Question, QuizState.End].includes(quizData.data.state)){
                setTransition([true, ""]);

                setTimeout(function() {
                    setTransition([false, ""]);
                }, 800);
            }
        });
 
    }, [hub])

    var content = null;
    var key = "";
    var ref = null;

    switch (data.state) {
        case QuizState.AwaitingStart:
            content = <AwaitingStart/>
            key = "AwaitingStart"
            ref = awaitingStartRef
            break;
        case QuizState.RoundStart:
            content = <Round round={data.round}/>
            key = "Round"
            ref = roundRef
            break;
        case QuizState.Question:
            content = <Question data={data}/>
            key = "Question"
            ref = questionRef
            break;
        case QuizState.End:
            content = <Ended/> 
            key = "Ended"
            ref = endedRef
            break;
        default:
            content = <AwaitingQuestion/>
            key = "AwaitingQuestion"
            ref = awaitingRef
            break;
    }

    if(transition[0]){
        content =  <FullScreen text={transition[1]}/>
        key=  "Incoming";
        ref = incomingRef
    }

   
    return(
        <SwitchTransition mode='in-out'>
            <CSSTransition
                timeout={{
                    enter: 0,
                    exit: 500,
                }}
                key={key}
                nodeRef={ref}
                classNames="slide">
                <div ref={ref} className="slide-container">{content}</div>
            </CSSTransition>
        </SwitchTransition>
     )

}