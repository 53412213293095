import React, { useState, useContext, useEffect } from "react";
import HubContext from "utils/hubProvider";

import { QuizState } from "enumerations/QuizState";

import adminApi from "services/AdminApi";
import { Button, Progress } from "components";
import { AdminProgress } from "./AdminProgress/AdminProgress";
import { QuestionType } from "enumerations/QuestionType";
import { AdminButtons } from "./AdminButtons";


export function AdminContent(props) {
    const hub = useContext(HubContext);
    const [data, setData] = useState(props.data);

    //Setup Hub    
    useEffect(() => {
        if (hub == null)
            return;

        hub.off('step-changed');
        hub.on('step-changed', refreshData);

        hub.off('state-changed');
        hub.on('state-changed', refreshData);

        hub.off('answered');
        hub.on('answered', refreshData);

        hub.off('validated');
        hub.on('validated', refreshData);
    }, [hub])

    async function refreshData() {
        var adminData = await adminApi.adminData();
        setData(adminData.data);
    }

    async function start() {
        await adminApi.startQuiz();
    }

    async function startDemo() {
        const demoCount = prompt('Demo Count')
        await adminApi.startDemoQuiz(demoCount);
    }

    async function reset() {
        await adminApi.resetQuiz();
    }

    async function viewRound(roundId) {
        await adminApi.roundView(roundId);
    }

    async function viewQuestion(questionId) {
        await adminApi.questionView(questionId);
    }

    async function viewAnswer(answerId) {
        await adminApi.answerView(answerId);
    }

    async function viewScores() {
        await adminApi.scoresView();
    }

    async function endQuiz() {
        var res = window.confirm("Are you sure?");
        if (!res)
            return;

        await adminApi.end();
    }


    async function audio(play) {
        await adminApi.audio(play);
    }

    async function zoom(zoomIn) {
        await adminApi.zoom(zoomIn);
    }

    async function timer(start) {
        await adminApi.timer(start);
    }

    // async function countdown(start) {
    //     await adminApi.countdown(start);
    // }

    async function changeStep(positive) {
        var max = data.question.options.length - 1;
        var step = positive ? data.step + 1 : data.step - 1;

        if (step < 0 || step > max)
            return;

        await adminApi.changeStep(step);
    }

    if (data.state === QuizState.AwaitingStart || data.state === QuizState.End) {
        return (
            <div className="admin-content p-3">
                <div className='admin-start'>
                    <Button className="button-demo" onClick={startDemo}>Demo</Button>
                    <Button onClick={start}>Start Quiz</Button>
                    <Button onClick={reset}>Reset Quiz</Button>
                </div>
            </div>
        )
    }

    if (data == null)
        return null;

    var userAnswers = data.playerAnswers;
    var validated = data.playerAnswers.filter(e => e.validated);
    var roundQuestionCount = data.questions.filter(e => e.round === data.question.round).length;

    return (
        <div className="admin-content p-3">
            <AdminProgress progress={userAnswers.length} progress2={validated.length} total={data.players.length} label="Answered" label2="Validated" icon="account_circle" />

            <div className="admin-header p-3">
                <span className="round-num">Round {data.question.round} <span className="total">/ {data.rounds.length}</span></span>
                <span className="question-num">Question {data.question.number} <span className="total">/ {roundQuestionCount}</span></span>

                <div className="seperator" />

                <AdminButtons data={data} showBonuses={props.showBonuses} viewScores={viewScores} zoom={zoom} audio={audio} timer={timer}/>
            </div>

            <div className='admin-questions'>
                {data.rounds.map((round, index) => {
                    var questions = data.questions.filter(e => e.round === round.roundId);
                    var roundActive = data.state === QuizState.RoundStart && data.question.round === round.roundId;

                    return (
                        <div key={index} className='admin-round'>
                            <button key={index} className={`round ${roundActive ? 'selected' : ''}`} onClick={() => viewRound(round.roundId)}>Round {questions[0].round}</button>

                            {questions.map((question, index) => {
                                var questionActive = data.question.id === question.id && data.state === QuizState.Question;
                                var answerActive = data.question.id === question.id && data.state === QuizState.Answer;
                                return (
                                    <div key={index} className={`admin-question ${questionActive || answerActive ? 'active' : ''}`}>
                                        <div className="admin-question-content">
                                            <div className='admin-question-text'>
                                                <p>Question {question.number}</p>
                                                {question.answered ? <p className="question-answered">Answered</p> : null}
                                            </div>
                                            <Button className={questionActive ? 'active' : ''} small onClick={() => viewQuestion(question.id)}>Q</Button>
                                            <Button className={answerActive ? 'active' : ''} small onClick={() => viewAnswer(question.id)}>A</Button>
                                        </div>

                                        {data.question.id === question.id && question.type === QuestionType.ConfirmChoice ?
                                            <div className="admin-question-content">
                                                <div className='admin-question-text'>
                                                    <p>Step {data.step + 1} / {question.options.length}</p>
                                                </div>
                                                <Button small onClick={() => changeStep(false)}>-</Button>
                                                <Button small onClick={() => changeStep(true)}>+</Button>
                                            </div>
                                            : null}
                                    </div>
                                );
                            })}
                        </div>
                    );
                })}


                <button className={`round ${data.state === QuizState.End ? 'selected' : ''}`} onClick={() => endQuiz()}>End</button>
            </div>
        </div>
    )

}