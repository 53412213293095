import { useState, useEffect } from 'react';

const useCanScroll = (scrollClass) => {
    const [canScroll, setCanScroll] = useState(false);

    useEffect(() => {
        const handleScroll = (event) => {
            updateCanScroll(event.target)

        };
        
        const updateCanScroll = (ele) => {
            const hasScrollableContent = ele.scrollHeight > ele.clientHeight;
            const overflowYStyle = window.getComputedStyle(ele).overflowY;
            const isOverflowHidden = overflowYStyle.indexOf('hidden') !== -1;
            var valid = hasScrollableContent && !isOverflowHidden;

            if (valid) {
                const scrolledTo = ele.scrollTop + ele.offsetHeight;


                const threshold = (ele.scrollHeight - ele.offsetHeight) * 0.1;
                const isReachBottom =
                    ele.scrollHeight - threshold <= scrolledTo;

                if (!isReachBottom) {
                    // console.log(true);
                    setCanScroll(true);
                    return;
                }
            }

            // console.log(false);

            setCanScroll(false);
        }


        var container = document.querySelector(`.${scrollClass}`);
        container.addEventListener('scroll', handleScroll);
        updateCanScroll(container);
        // Clean up the event listener when the component unmounts
        return () => {
            container.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return canScroll;
};

export default useCanScroll;