import styles from './Styles/Round.module.css';

export function Round(props){
    return(
        <div className={styles.container}>
            <span className={styles.round_num}>Round {props.round.roundId}</span>

            <div className="seperator"/>

            <span className={styles.round_text}>{props.round.text}</span>
        </div>
     )

}