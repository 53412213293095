import logo from 'assets/images/quiz-logo.png';
import styles from './Styles/Ended.module.css';

export function Ended(){

    return(
        <div className={styles.container}>
            <img src={logo} alt="Logo" />
            <p>Quiz Ended</p>
        </div>
     )

}