import React, {useState, useContext} from "react";
import HubContext from "utils/hubProvider";
import { Button, Media, TextInput } from "components";
import { getSessionId } from "utils/helper";
import { useButtonTransition } from "hooks/useButtonTransition";
import quizApi from "services/QuizApi";
import styles from './Inputs.module.css';

export function DateInput(props){
    const [answer, setAnswer] = useState(props.answer != null ? props.answer.answer[0] : null);
    const hub = useContext(HubContext);
    const [error, setError] = useState(null);
    const { transition, transitionContent } = useButtonTransition() 
    const submitRef = React.createRef()

    async function submit() {
        var min = props.question.extraDetails.Min
        var max = props.question.extraDetails.Max
    
        if(answer === null || answer === ""){
          setError("Please enter a date")
          return;
        }
    
        var answerInt = parseInt(answer);
        if(answerInt < min || answerInt > max) {
          setError(`Date must be between ${min} and ${max}`)
          return;
        }

        const data = {
            "QuestionId" : props.question.id,
            "Answer" : [answer],
        };

        var submitError = null;
        transition('.btn-submit', "Submitting...", 
            async () => {
                await hub.invoke("Answer", getSessionId(), data)
                    .catch(async (error) => {
                        var res = await quizApi.answer(data);
                        if(!res.success)
                            throw new Error(res.error);
                    })
                    .catch((error) => {
                        submitError = "Failed to submit answer...";
                    });
            },
            () => {
                setError(submitError);
            });
    }

    function scrollToButton() {
        submitRef.current?.scrollIntoView({ behavior: 'smooth' })
    }


    var buttonContent = null;

    if(props.answer){
        buttonContent = <span className={styles.submitted_text}>Submitted!</span>
    }
    else {
        buttonContent = <>
            <Button buttonRef={submitRef} className="btn-submit mt-5" onClick={submit}>Submit</Button>
            {error ? <span className="error">{error}</span> : null }
        </>
    }


    return (
        <div className={styles.container}>
            <Media media={props.media}/>

            <div className="w-100">
                <TextInput className={styles.text_input} placeholder="Date" keyboard="numeric" type="number" value={answer} onLostFocus={() => scrollToButton()}
                            onChange={(event) => setAnswer(event.target.value)} disabled={props.answer}/>
            
                <div className={styles.min_max}>
                    <span>Min: {props.question?.extraDetails?.Min}</span>
                    <span>Max: {props.question?.extraDetails?.Max}</span>
                </div>
            </div>

            {buttonContent}
            {transitionContent}
        </div>
    )
}
