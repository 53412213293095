import React, { useState, useContext, useEffect, useRef } from "react";

import styles from '../Styles/AnswerView.module.css';
import HubContext from "utils/hubProvider";
import moment from "moment";

export function TimeToSingTimer(props) {
    const [startTime, setStartTime] = useState(null);
    const [answerTime, setAnswerTime] = useState(null);
    const [running, setRunning] = useState(null);
    const [timeNow, setTimeNow] = useState(moment());
    const hub = useContext(HubContext);
    let interval = useRef(null);

    //Setup Hub
    useEffect(() => {
        const running = (seconds, max, timerStart) => {
            setRunning(true);
            var start = moment(timerStart);
            setStartTime(start);

            var duration = moment.duration(`00:${props.data.answer.answer[0]}`);
            setAnswerTime(start.clone().add(duration))
        }

        hub.off('timer-start');
        hub.on('timer-start', (seconds, max, timerStart) => {
            running(seconds, max, timerStart);

            if (props.onTimerStart)
                props.onTimerStart();
        });

        hub.off('timer-tick');
        hub.on('timer-tick', running);

        hub.off('timer-stop');
        hub.on('timer-stop', () => {
            setRunning(false);

            if (props.onTimerStop)
                props.onTimerStop();
        });
    }, [hub])


    useEffect(() => {
        if (startTime != null && running) {
            if (interval.current != null)
                return;

            interval.current = setInterval(() => {
                setTimeNow(moment())

                if (timeNow != null && answerTime != null && timeNow > answerTime) {
                    clearInterval(interval.current)
                    interval.current = null;

                    props.onHitAnswer();
                    return;
                }
            }, 10);
        }
        else {
            clearInterval(interval.current)
            interval.current = null;
        }

        return () => {
            clearInterval(interval.current);
            interval.current = null;
        }
    }, [startTime, running])




    var time = "00:00.00";
    var hitAnswer = false;
    if (timeNow != null && answerTime != null && timeNow > answerTime) {
        time = moment.utc(answerTime).subtract(startTime).format("mm:ss.SS");
        hitAnswer = true;
    }
    else if (running && startTime != null && timeNow > startTime) {
        time = moment.utc(timeNow).subtract(startTime).format("mm:ss.SS");
    }

    return (
        <p className={`${styles.timer} ${hitAnswer ? styles.hit_answer : ""}`}>{time}</p>
    )
}