import React, { useState, useEffect } from 'react';
import styles from './AudioTester.module.css';
import { Button } from 'components/Button/Button';
import { useAudioPlayer } from 'react-use-audio-player';
import tada from 'assets/media/tada.mp3';
import Volume from 'utils/volume';

export function AudioTester(props) {
    const [musicVolume, setMusicVolume] = useState(0.5);
    const [sfxVolume, setSfxVolume] = useState(0.5);
    const musicPlayer = useAudioPlayer();
    const sfxPlayer = useAudioPlayer();

    const playMusic = () => {
        sfxPlayer.stop();

        if (musicPlayer.playing)
            musicPlayer.stop();
        else
            musicPlayer.load('/media/shakin-test.mp3', { autoplay: true, initialVolume: musicVolume });
    }

    const playSfx = () => {
        musicPlayer.stop();

        if (sfxPlayer.playing)
            sfxPlayer.stop();
        else
            sfxPlayer.load(tada, { autoplay: true, initialVolume: sfxVolume });
    }

    const save = () => {
        musicPlayer.stop();
        sfxPlayer.stop();

        var volume = new Volume(musicVolume, sfxVolume);
        props.onSave(volume);
    }

    return (
        <div className={styles.audio_container}>

            <div className={styles.audio_group}>
                <label>Music Audio</label>
                <div className={styles.slide_container}>
                    <input
                        className={styles.slider}
                        type="range"
                        min={0}
                        max={1}
                        step={0.02}
                        value={musicVolume}
                        onChange={event => {
                            setMusicVolume(event.target.valueAsNumber)
                            musicPlayer.setVolume(event.target.valueAsNumber)
                        }}
                    />
                    <Button icon onClick={playMusic}>play_arrow</Button>

                </div>

            </div>

            <div className={styles.audio_group}>
                <label>Sfx Audio</label>
                <div className={styles.slide_container}>
                    <input
                        className={styles.slider}
                        type="range"
                        min={0}
                        max={1}
                        step={0.02}
                        value={sfxVolume}
                        onChange={event => {
                            setSfxVolume(event.target.valueAsNumber)
                            sfxPlayer.setVolume(event.target.valueAsNumber)
                        }}
                    />
                    <Button icon onClick={playSfx}>play_arrow</Button>

                </div>


                {/* <audio controls ref={(audioref) => { setAudioBackground(audioref) }}>
                    <source src='/media/background.mp3' type="audio/mpeg" />
                    Your browser does not support the audio tag.
                </audio> */}
            </div>

            <Button className="mt-5" onClick={save}>Save Audio Settings</Button>

        </div>
    );
};

export default React.memo(AudioTester, (prevProps, nextProps) => {
    return prevProps.volume === nextProps.volume;
});
