import { useState, useEffect } from 'react'

const useScreenOrientation = () => {
  const isLandscape = () => window.matchMedia('(orientation:landscape)').matches
  const [orientation, setOrientation] = useState(isLandscape() ? 'landscape' : 'portrait')

  const onWindowResize = () => {
    clearTimeout(window.resizeLag)
    window.resizeLag = setTimeout(() => {
      delete window.resizeLag
      setOrientation(isLandscape() ? 'landscape' : 'portrait')
    }, 200)
  }


  useEffect(() => (
    onWindowResize(),
    window.addEventListener('resize', onWindowResize),
    () => window.removeEventListener('resize', onWindowResize)
  ), [])

  return orientation
}

export default useScreenOrientation