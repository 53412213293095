import React, { useState, useContext, useEffect, useCallback, useRef } from "react";

import styles from '../Styles/AnswerView.module.css';
import { Breadcrumbs } from 'components';
import logo from 'assets/images/quiz-logo.png';
import HubContext from "utils/hubProvider";
import { PlayerImage } from "components/PlayerImage/PlayerImage";
import { Flipped, Flipper } from "react-flip-toolkit";
import { Map, Marker, useMap } from '@vis.gl/react-google-maps';
import mapStyles from 'views/MapStyles';
import { delay } from "utils/helper";
import { useAudioPlayer } from 'react-use-audio-player';
import pop from 'assets/media/pop2.mp3';
import drumroll from 'assets/media/drumroll.mp3';
import VolumeContext from "utils/volumeProvider";

export function MapAnswerView(props) {
    const [answer] = useState(props.data.answer?.answer.map(e => parseFloat(e)));
    const [sortedAnswers, setSortedAnswers] = useState(props.data.playerAnswers);
    const [markers, setMarkers] = useState(Array(props.data.playerAnswers.length).fill(false));
    const [showAnswer, setShowAnswer] = useState(false);
    const [showAnswerAnimation, setShowAnswerAnimation] = useState(true);
    const volume = useContext(VolumeContext);

    const hub = useContext(HubContext);
    const map = useMap();
    const player = useAudioPlayer();

    const answerFocused = useRef(false);
    const mapRef = useRef();

    //Setup Hub
    useEffect(() => {
        hub.off('zoom-in');
        hub.on('zoom-in', () => {
            mapRef.current.setZoom((mapRef.current.getZoom() + 1));
        });

        hub.off('zoom-out');
        hub.on('zoom-out', () => {
            mapRef.current.setZoom((mapRef.current.getZoom() - 1));
        });

        hub.off('zoom-fit');
        hub.on('zoom-fit', async () => {
            fitBounds(!answerFocused.current, mapRef.current)
        });
    }, [hub])

    //Setup Hub
    useEffect(() => {
        player.load(pop, {initialVolume: volume.sfx});

        setShowAnswerAnimation(true);
        setShowAnswer(false);
        setMarkers(Array(props.data.playerAnswers.length).fill(false));

        async function animate() {
            await delay(3000);

            var newMarkers = [...markers]
            for (let index = 0; index < props.data.playerAnswers.length; index++) {
                newMarkers[index] = true;
                player.play();
                setMarkers((old) => {
                    var newArr = [...old];
                    newArr[index] = true;
                    return newArr;
                });


                await delay(200);
            }

            await delay(1000);

            setShowAnswer(true);

            player.load(drumroll, { autoplay: true, initialVolume: volume.sfx});

            await delay(2300);

            setShowAnswerAnimation(false);

            var sorted = [...sortedAnswers];
            sorted.sort(sortAns);
            setSortedAnswers(sorted);
        }

        animate();

    }, [])


    const fitBounds = useCallback((focusedOnAnswer, map) => {
        const bounds = new window.google.maps.LatLngBounds();

        var players = props.data.playerAnswers;

        if (focusedOnAnswer) {
            players = props.data.playerAnswers.filter(e => e.score > 0)
            answerFocused.current = true;
        }
        else {
            answerFocused.current = false;
        }

        for (var i = 0; i < players.length; i++) {
            var playerPoints = players[i].answer.map(e => parseFloat(e));

            bounds.extend({ lat: playerPoints[0], lng: playerPoints[1] });
        }

        bounds.extend({ lat: answer[0], lng: answer[1] });


        map.fitBounds(bounds, 0);
        if(focusedOnAnswer){
            map.setZoom(map.getZoom() - 1);
        }
    }, [answer, props.data]);


    useEffect(() => {
        if (!map) return;
        mapRef.current = map;

        map.setZoom(3);

        fitBounds(showAnswer, map);
    }, [map, showAnswer, fitBounds]);


    function sortAns(ob1, ob2) {
        if (ob1.score < ob2.score) {
            return 1;
        } else if (ob1.score > ob2.score) {
            return -1;
        }

        var ob1distance = parseFloat(ob1.displayAnswer)
        var ob2distance = parseFloat(ob2.displayAnswer)

        // Else go to the 2nd item
        if (ob1distance < ob2distance) {
            return -1;
        } else if (ob1distance > ob2distance) {
            return 1
        } else { // nothing to split them
            return 0;
        }
    }

    var flipKey = sortedAnswers.map(e => e.userId).join(',');
    return (
        <div className={styles.content_container}>
            <img className={styles.logo} src={logo} alt="Logo" />

            <div className={styles.container}>

                <div className={styles.left}>
                    <div className={styles.header}>
                        <Breadcrumbs {...props} />
                    </div>

                    <div className={styles.left_content}>

                        <Flipper className="score-container" flipKey={flipKey}
                            spring="stiff"
                            staggerConfig={{
                                default: {
                                    speed: 0.1
                                }
                            }}>

                            <div className={styles.player_answers}>
                                {sortedAnswers.map((answer, index) => {
                                    var player = props.data.players.find(e => e.id === answer.userId);
                                    var score = !showAnswerAnimation ? answer?.score : null;

                                    return (
                                        <Flipped key={index} flipId={player.id}>

                                            <div key={index} className={`${styles.player_answer} ${score ? styles.has_score : ""}`}>
                                                <div className={styles.position}>{!showAnswerAnimation ? index + 1 : ""}</div>
                                                <div className={styles.player_container}>
                                                    <PlayerImage className={styles.player_image} player={player} withoutName={true} />
                                                </div>
                                                <div className={styles.player_content}>
                                                    <p className={styles.player_time}>{!showAnswerAnimation ? answer.displayAnswer : "----"}</p>
                                                    <p className={styles.player_name}>{player.name}</p>
                                                </div>
                                                {score ? <span className={styles.score}>+{score}</span> : null}
                                            </div>

                                        </Flipped>
                                    )

                                })}
                            </div>
                        </Flipper>


                    </div>
                </div>

                <div className={`${styles.right}`}>
                    <div className={styles.header}>

                    </div>

                    <div className={styles.right_content}>

                        <Map
                            // mapId={'b2a1a2e1f7e9017a'}

                            style={{ borderRadius: '1rem' }}
                            zoom={3}
                            center={{ lat: 53.4659131, lng: -1.5707844 }}
                            gestureHandling={'greedy'}
                            fullscreenControl={false}
                            // onClick={(e) => !props.answer ? setAnswer([e.detail.latLng.lat, e.detail.latLng.lng]) : null}
                            on
                            disableDefaultUI={true}
                            streetViewControl={false}
                            clickableIcons={false}
                            mapTypeControl={false}
                            styles={mapStyles}>

                            {showAnswer ? <Marker
                                position={{ lat: answer[0], lng: answer[1] }}
                                className={styles.answer_marker}
                                animation={showAnswerAnimation ? 1 : null}
                                icon='/media/images/marker-grey.png'
                            /> : null
                            }

                            {markers.map((visible, index) => {
                                var player = props.data.playerAnswers[index];
                                var playerPoints = player.answer.map(e => parseFloat(e));
                                var sortedIndex = sortedAnswers.indexOf(player);
                                return <Marker
                                    key={index}
                                    icon={showAnswerAnimation ? '/media/images/marker-red.png' : '/media/images/marker-red-filled.png'}
                                    // label={!showAnswerAnimation ? sortedIndex.toString() : ""}
                                    label={!showAnswerAnimation ? { text: (sortedIndex + 1).toString(), color: "white", fontSize: "16px", className: styles.marker_position } : ""}
                                    // title={showAnswerAnimation && player.score > 0 ? player.score : ""}
                                    position={{ lat: playerPoints[0], lng: playerPoints[1] }}
                                    visible={visible} />
                            })}


                        </Map>
                    </div>
                </div>
            </div>
        </div>
    )
}