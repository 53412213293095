import styles from './Styles/RoundView.module.css';
import { useContext, useEffect, useRef, useState } from 'react';
import slide from 'assets/media/slide.wav';
import slide2 from 'assets/media/slide2.wav';
import { useAudioPlayer } from 'react-use-audio-player';
import VolumeContext from 'utils/volumeProvider';

export function RoundView(props){
    const [seperatorActive, setSeperatorActive] = useState(false);
    const [roundActive, setRoundActive] = useState(false);
    const [textActive, setTextActive] = useState(false);
    const player = useAudioPlayer();
    const volume = useContext(VolumeContext);


    useEffect(() => {
        player.load(slide, {initialVolume: volume.sfx});

        setTimeout(() => {
            player.play();
            setSeperatorActive(true);
          
            setTimeout(() => {
                player.load(slide2, {autoplay: true, initialVolume: volume.sfx});
                setRoundActive(true);

                setTimeout(() => {
                    player.play();
                    setTextActive(true);
                }, 1000)
            }, 1000)
        }, 3000)
     
    
      }, [])

    return(
        <div className={styles.container}>
            <div className={`${styles.round_num} ${roundActive ? styles.active : ''}`}>
                <span>Round {props.round.roundId}</span>
            </div>

            <div className={`${styles.seperator} ${seperatorActive ? styles.active : ''}`}/>

            <div className={`${styles.round_text} ${textActive ? styles.active : ''}`}>
                <span>{props.round.text}</span>
            </div>
        </div>
     )
}