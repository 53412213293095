import axios from 'axios';
import { getSessionId } from '../utils/helper';
import { apiErrorResult, apiSuccessResult } from './ApiResult';

const quizApi = { 
    started: async () => { 
        const URL = "api/quiz/started"; 
        return await axios.get(URL) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    }, 

    quizData: async () => { 
        const URL = `api/quiz/data/${getSessionId()}`; 
        return await axios.get(URL) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    }, 

    answer: async (answerData) => { 
        const URL = `api/quiz/answer/${getSessionId()}`; 
        return await axios.post(URL, answerData) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    },

    scores: async () => { 
        const URL = `api/quiz/scores`; 
        return await axios.get(URL) 
            .then((res) =>  apiSuccessResult(res.data))
            .catch((err) => apiErrorResult(err))
    } 
} 
export default quizApi; 