import React, { useState, useEffect, useContext } from "react";
import HubContext from "utils/hubProvider";
import { useAudioPlayer } from 'react-use-audio-player';
import { MediaType } from "enumerations/MediaType";
import { ViewerWithMedia } from "../ViewWithMedia";
import { ViewWithoutMedia } from "../ViewWithoutMedia";
import { QuestionType } from "enumerations/QuestionType";
import { TimeToSingAnswerView } from "./TimeToSingAnswerView";
import { MapAnswerView } from "./MapAnswerView";
import { APIProvider } from "@vis.gl/react-google-maps";
import { DateAnswerView } from "./DateAnswerView";
import VolumeContext from "utils/volumeProvider";

export function AnswerView(props) {
    const [showCorrectPlayers, setShowCorrectPlayers] = useState(false)
    const hub = useContext(HubContext);
    const player = useAudioPlayer();
    const volume = useContext(VolumeContext);

    useEffect(() => {
        if (props.data.media?.mediaType === MediaType.Audio) {
            var media = `/media/${props.data.media.path}`;
            player.load(media, {initialVolume: volume.music});
        }

        return function cleanup() {
            player.cleanup();
        };
    }, []);

    //Setup Hub
    useEffect(() => {
        hub.off('play');
        hub.on('play', play);

        hub.off('pause');
        hub.on('pause', stop);

        // hub.off('timer-start');
        // hub.on('timer-start', play);

        // hub.off('timer-stop');
        // hub.on('timer-stop', stop);
    }, [hub])


    function play() {
        player.stop();
        player.play();
    }

    function stop() {
        player.stop();
    }

    function OnAnswerShown() {
        setShowCorrectPlayers(true);
    }

    if (props.data.question.type === QuestionType.TimeToSing) {
        return <TimeToSingAnswerView {...props} playing={player.playing} onTimerStart={play} onTimerStop={stop}/>
    }
    else if (props.data.question.type === QuestionType.Map) {
        return <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}><MapAnswerView {...props} /></APIProvider>
    }
    else if (props.data.question.type === QuestionType.Date) {
        return <DateAnswerView {...props} />
    }
    else if (props.data.media) {
        return <ViewerWithMedia {...props} answerView={true} players={props.data.players} answers={props.data.playerAnswers} showCorrectPlayers={showCorrectPlayers} onAnswerShown={OnAnswerShown} />
    }
    else {
        return <ViewWithoutMedia {...props} answerView={true} players={props.data.players} answers={props.data.playerAnswers} showCorrectPlayers={showCorrectPlayers} onAnswerShown={OnAnswerShown} />
    }
}
