import React, { useState } from "react";

import styles from '../Styles/AnswerView.module.css';
import { Breadcrumbs, Media } from 'components';
import logo from 'assets/images/quiz-logo.png';
import moment from "moment";
import { PlayerImage } from "components/PlayerImage/PlayerImage";
import { Flipped, Flipper } from "react-flip-toolkit";
import { TimeToSingTimer } from "./TimeToSingTimer";

export function TimeToSingAnswerView(props) {
    const [sortedAnswers, setSortedAnswers] = useState(props.data.playerAnswers);
    const [hasHitMax, setHasHitMax] = useState(false);
   
    const onHitMax = () => {
        setHasHitMax(true);

        var sorted = [...sortedAnswers];
        sorted.sort(sortAns);
        setSortedAnswers(sorted);
    }

    function sortAns(ob1, ob2) {
        if (ob1.score < ob2.score) {
            return 1;
        } else if (ob1.score > ob2.score) {
            return -1;
        }


        var ansDuration = moment.duration(`00:${props.data.answer.answer[0]}`);
        var ob1Duration = moment.duration(`00:${ob1.answer[0]}`);
        var ob2Duration = moment.duration(`00:${ob2.answer[0]}`);

        var ob1Diff = Math.abs(ob1Duration.valueOf() - ansDuration.valueOf())
        var ob2Diff = Math.abs(ob2Duration.valueOf() - ansDuration.valueOf())

        // Else go to the 2nd item
        if (ob1Diff < ob2Diff) {
            return -1;
        } else if (ob1Diff > ob2Diff) {
            return 1
        } else { // nothing to split them
            return 0;
        }
    }

    var flipKey = sortedAnswers.map(e => e.userId).join(',');

    return (
        <div className={styles.content_container}>
            <img className={styles.logo} src={logo} alt="Logo" />

            <div className={styles.container}>

                <div className={styles.left}>
                    <div className={styles.header} onClick={onHitMax}>
                        <Breadcrumbs {...props} />
                    </div>

                    <div className={styles.left_content}>

                        <Flipper className="score-container" flipKey={flipKey}
                            spring="stiff"
                            staggerConfig={{
                                default: {
                                    speed: 0.1
                                }
                            }}>

                            <div className={styles.player_answers}>
                                {sortedAnswers.map((answer, index) => {
                                    var player = props.data.players.find(e => e.id === answer.userId);
                                    var score = hasHitMax ? answer?.score : null;


                                    return (
                                        <Flipped key={index} flipId={player.id}>

                                            <div key={index} className={`${styles.player_answer} ${score ? styles.has_score : ""}`}>
                                                <div className={styles.position}>{hasHitMax ? index + 1 : ""}</div>
                                                <div className={styles.player_container}>
                                                    <PlayerImage className={styles.player_image} player={player} withoutName={true} />
                                                </div>
                                                <div className={styles.player_content}>
                                                    <p className={styles.player_time}>{answer.answer[0]}</p>
                                                    <p className={styles.player_name}>{player.name}</p>
                                                </div>
                                                {score ? <span className={styles.score}>+{score}</span> : null}
                                            </div>

                                        </Flipped>
                                    )

                                })}
                            </div>
                        </Flipper>


                    </div>
                </div>

                <div className={`${styles.right}`}>
                    <div className={styles.header}>
                        <TimeToSingTimer {...props} onHitAnswer={onHitMax} />
                    </div>

                    <div className={styles.right_content}>
                        <div className={styles.media_container}>
                            <Media media={props.data.media} isRunning={props.playing} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}