import React, { useEffect, useContext, useState } from "react";
import logo from 'assets/images/quiz-logo.png';
import HubContext from "utils/hubProvider";
import { useAudioPlayer } from 'react-use-audio-player';
import pop from 'assets/media/pop.mp3';
import styles from './Styles/StartView.module.css';
import { PlayerImage } from "components";

export function StartView(props){
    const hub = useContext(HubContext);
    const [players, setPlayers] = useState(props.data.players.concat(Array(18).fill(null)).slice(0,18));
    const { load } = useAudioPlayer();


    //Setup Hub    
    useEffect(() => {
        if(hub == null)
            return;

        hub.off('players-updated');
        hub.on('players-updated', async (newPlayers) => {
            let newArr = [...players];

            newPlayers.forEach(newPlayer => {
                var existingPlayerIndex = newArr.findIndex(e => e?.id === newPlayer.id);
                
                if(existingPlayerIndex > -1){
                    newArr[existingPlayerIndex] = newPlayer;
                    setPlayers(newArr)
                }
                else {
                    var freeIndexes = newArr.reduce(function(a, e, i) {
                        if (e === null)
                            a.push(i);
                        return a;
                    }, []); 

                    var newPos = Math.floor(Math.random() * (freeIndexes.length));
                    newArr[freeIndexes[newPos]] = newPlayer;
                    setPlayers(newArr)

                    load(pop, {
                        autoplay: true
                    });
                }
            });
        });
    }, [hub, players])

    return(
        <div className={styles.container}>
            <div className={styles.players_container}>
                <div className={styles.players_row}>
                    <PlayerImage player={players[0]}/>
                    <PlayerImage player={players[1]}/>
                    <PlayerImage player={players[2]}/>
                </div>
                <div className={styles.players_row}>
                    <PlayerImage player={players[3]}/>
                    <PlayerImage player={players[4]}/>
                    <PlayerImage player={players[5]}/>
                </div>
                <div className={styles.players_row}>
                    <PlayerImage player={players[6]}/>
                    <PlayerImage player={players[7]}/>
                    <PlayerImage player={players[8]}/>
                </div>
            </div>

            <div className={styles.logo_container}>
                <img src={logo} alt="Logo" />
                <div className={styles.url}><span>Go to</span><span className={styles.highlight}>quiz.georgej.co.uk</span><span>to join the quiz</span></div>  
            </div>
                
            <div className={styles.players_container}>
                <div className={styles.players_row}>
                    <PlayerImage player={players[9]}/>
                    <PlayerImage player={players[10]}/>
                    <PlayerImage player={players[11]}/>
                </div>
                <div className={styles.players_row}>
                    <PlayerImage player={players[12]}/>
                    <PlayerImage player={players[13]}/>
                    <PlayerImage player={players[14]}/>
                </div>
                <div className={styles.players_row}>
                    <PlayerImage player={players[15]}/>
                    <PlayerImage player={players[16]}/>
                    <PlayerImage player={players[17]}/>
                </div>
            </div>
        </div>
        )

}
