import styles from './Styles/AwaitingQuestion.module.css';

export function AwaitingQuestion(){

    return(
        <div className={styles.container}>
            <p>Awaiting Question...</p>
        </div>
     )

}