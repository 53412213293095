import React, { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import { Game } from "./Game/Game";
import { AlreadyStarted } from "./Join/AlreadyStarted";
import { Join } from "./Join/Join";

import { FullScreen, ConnectionError } from "components";
import { HubProvider } from 'utils/hubProvider'

import userApi from 'services/UserApi'
import quizApi from "services/QuizApi";

import useScreenOrientation from "hooks/useScreenOrientation";
import styles from './Player.module.css';


export function Player() {
    const screenOrientation = useScreenOrientation()

    const [hubConnection, setHubConnection] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [data, setData] = useState(null);
    const [alreadyStarted, setAlreadyStarted] = useState(false);
    const connectingRef = React.useRef(null);
    const gameRef = React.useRef(null);
    const joinRef = React.useRef(null);
    const alreadyStartedRef = React.useRef(null);

    const [isReconnecting, setIsReconnecting] = useState(false);
    const [connectionFailed, setConnectionFailed] = useState(false);


    //Verify
    useEffect(() => {
        setIsLoading(true)
        async function verifyUser() {
            var result = await userApi.verify();
            if (result.success) {
                await connect()

                setUser(result.data);
            }
            else {
                //User Not Exists
                var started = await quizApi.started();
                if (started.success) {
                    setAlreadyStarted(started.data === true)
                }
            }

            setIsLoading(false)
        }
        verifyUser();
    }, [])


    async function joined(user) {
        var quizData = await quizApi.quizData();
        setData(quizData.data);

        setUser(user);
    }

    async function connect() {
        setConnectionFailed(false);
        setIsReconnecting(false);

        var conn = new HubConnectionBuilder()
            .withUrl(`hub/quiz-hub`)
            .withAutomaticReconnect()
            .build();

        conn.onreconnected(async () => {
            var quizData = await quizApi.quizData();
            setData(quizData.data);
            
            setConnectionFailed(false);
            setIsReconnecting(false);
        })

        conn.onreconnecting((error) => {
            if (conn.state === HubConnectionState.Reconnecting) {
                setIsReconnecting(true);
            }
        });

        conn.onclose(async () => {
            await connect();
        });

        try {
            await conn.start();
            console.log('Connection started!');
            setHubConnection(conn);

            var quizData = await quizApi.quizData();
            setData(quizData.data);

            conn.on('reset', () => {
                conn.stop();
                setUser(null);
                setData(null);
            });

        } catch (error) {
            console.log('Failed to start!');
            setConnectionFailed(true);
        }
    }

    async function reconnect() {
        setIsLoading(true)
        connect()
        setIsLoading(false)
    }

    var content = null;
    var key = "";
    var ref = null;

    if (isLoading) {
        content = <FullScreen text="Connecting..." />
        key = "Connecting";
        ref = connectingRef;
    }
    else if (alreadyStarted) {
        content = <AlreadyStarted />
        key = "AlreadyStarted";
        ref = alreadyStartedRef;
    }
    else if (user != null && user.image != null) {
        content = <Game data={data} />
        key = "Game";
        ref = gameRef;
    }
    else {
        content = <Join user={user} connect={connect} joined={joined} />
        key = "Join";
        ref = joinRef;
    }

    if (screenOrientation?.includes("landscape") ?? false) {
        return <FullScreen text="Rotate Screen To Portrait" />
    }

    return (
        <div className={styles.container}>
            <ConnectionError isReconnecting={isReconnecting} connectionFailed={connectionFailed} reconnect={reconnect} />
            <div className={styles.content}>
                <HubProvider value={hubConnection}>
                    <SwitchTransition mode='in-out'>
                        <CSSTransition
                            timeout={{
                                enter: 0,
                                exit: 500,
                            }}
                            key={key}
                            nodeRef={ref}
                            classNames="slide">
                            <div ref={ref} className="slide-container">{content}</div>
                        </CSSTransition>
                    </SwitchTransition>
                </HubProvider>
            </div>
        </div>
    )
}