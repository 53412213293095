import React from "react";
import styles from './Button.module.css';

export function Button(props){
  var className = styles.btn;

  if(props.small)
    className += " " + styles.small;

  if(props.icon)
    className +=  " " + styles.icon;

  if(props.noShadow)
    className +=  " " + styles.no_shadow;
  
  if(props.className)
    className += " " + props.className;

  return (
    <button ref={props.buttonRef} disabled={props.disabled} className={className} onClick={props.onClick}>{props.children}</button>
  )
}