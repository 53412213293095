import React from "react";
import logo from 'assets/images/quiz-logo.png';
import styles from './Join.module.css';

export function AlreadyStarted(){

    return(
        <div className={styles.started_container}>
            <img src={logo} alt="Logo" />
            <p>Quiz Already Started</p>
        </div>
     )
}