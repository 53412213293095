import React, { useEffect, useContext, useState } from "react";
import logo from 'assets/images/quiz-logo.png';
import styles from './Styles/ScoresView.module.css';
import { PlayerImage } from "components";
import { Flipped, Flipper } from "react-flip-toolkit";
import quizApi from "services/QuizApi";
import { delay, groupBy } from "utils/helper";
import { CountUp } from "use-count-up";

export function ScoresView(props) {
    const [players, setPlayers] = useState([]);
    const [animating, setAnimating] = useState(false);
    const [counting, setCounting] = useState(false);
    const [error, setError] = useState(false);

    //Setup Hub
    useEffect(() => {

        function buildPlayers(list) {
            var players = [];

            var posIndex = props.data.players.length - 1;
            const keys = Object.keys(list).sort((a, b) => parseInt(a) - parseInt(b));

            for (let position = 0; position < keys.length; position++) {
                const sorted = list[keys[position]];

                if (sorted == null) continue;
                for (let index = 0; index < sorted.length; index++) {
                    const score = sorted[index];
                    var player = props.data.players.find(e => e.id === score.userId);
                    player.oldScore = score.oldScore;
                    player.newScore = score.newScore;
                    player.position = keys.length - position - 1;
                    players[posIndex] = player;

                    posIndex--;
                }
            }

            return players;
        }

        async function init() {
            setPlayers([]);
            var scores = await quizApi.scores();

            if (!scores.success) {
                setError(true);
                return;
            }

            const groupedScores = groupBy(scores.data, "oldScore");
            setPlayers(buildPlayers(groupedScores));

            await delay(5000);

            setAnimating(true);
            console.log(`animating`);

            await delay(1000);

            setCounting(true);
            console.log(`counting`);

            await delay(1000);

            setCounting(false);

            const groupedScores2 = groupBy(scores.data, "totalScore");
            setPlayers(buildPlayers(groupedScores2));
            console.log(`sort`);
        }

        init();
    }, [])


    var scores = [];

    for (let index = 0; index < 18; index++) {
        var player = players[index];
        if (player == null)
            scores.push(<div className={styles[`score${index + 1}`]} />)
        else {
            // var color = index === 0 ? styles.gold : index === 1 ? styles.silver : index === 2 ? styles.bronze : null;
            var changeClass = `${styles.score_value}`;

            if (animating)
                changeClass += ` ${styles.merge_score}`;

            if (player.newScore < 0)
                changeClass += ` ${styles.negative}`;
            else if (player.newScore > 0)
                changeClass += ` ${styles.positive}`;

            var color = null;
            if (!players.every(e => e.position === 0))
                color = player.position === 0 ? styles.gold : player.position === 1 ? styles.silver : player.position === 2 ? styles.bronze : null;

            scores.push(
                <div className={styles[`score${index + 1}`]}>
                    <Flipped key={index} flipId={player.id}>

                        <div key={index} className={`${styles.player_score}  ${color}`}>
                            <div className={styles.position}>{player.position + 1}</div>
                            <div className={styles.player_container}>
                                <PlayerImage className={styles.player_image} player={player} withoutName={true} />
                            </div>
                            <div className={styles.player_content}>
                                <p className={styles.player_score_val}>
                                    <CountUp isCounting={counting} start={player.oldScore} end={player.oldScore + player.newScore} duration={1} />
                                </p>
                                <p className={styles.player_name}>{player.name}</p>
                            </div>
                            <span className={changeClass}>{player.newScore >= 0 ? '+' : '-'}{Math.abs(player.newScore)}</span>
                        </div>

                    </Flipped>
                </div>
            )
        }
    }


    var flipKey = players.map(e => e?.oldScore + e.newScore).join(',');

    return (
        <div className={styles.content_container}>
            <img className={styles.logo} src={logo} alt="Logo" />

            <div className={styles.container}>

                <div className={styles.left}>
                    <div className={styles.header} />
                </div>

                <div className={`${styles.right}`}>
                </div>
            </div>

            {error ?
                <div className={styles.error}>
                    <p>There was an error, refresh</p>
                </div> :

                <Flipper className={styles.score_container} flipKey={flipKey}
                    spring="stiff"
                    staggerConfig={{
                        default: {
                            speed: 0.1
                        }
                    }}>

                    <div className={styles.player_scores}>
                        {scores}
                    </div>
                </Flipper>
            }
        </div>
    )

}

