const mapStyles = [
    {
      featureType: 'all',
      elementType: 'all',
      stylers: [
        {saturation: '32'},
        {lightness: '-3'},
        {visibility: 'on'},
        {weight: '1.18'}
      ]
    },
    {
      featureType: 'administrative',
      elementType: 'labels',
      stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'administrative.land_parcel',
        elementType: 'all',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'administrative.locality',
        elementType: 'all',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'administrative.neighborhood',
        elementType: 'all',
        stylers: [{visibility: 'off'}]
    },
    {
        featureType: 'administrative.province',
        elementType: 'all',
        stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'landscape',
      elementType: 'labels',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'all',
      stylers: [{saturation: '-70'}, {lightness: '14'}, {visibility: 'off'}]
    },
    {
      featureType: 'poi',
      elementType: 'all',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'road',
      elementType: 'all',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'transit',
      elementType: 'labels',
      stylers: [{visibility: 'off'}]
    },
    {
      featureType: 'water',
      elementType: 'all',
      stylers: [{saturation: '100'}, {lightness: '-14'}]
    },
    {
      featureType: 'water',
      elementType: 'labels',
      stylers: [{visibility: 'off'}, {lightness: '12'}]
    }
  ]

export default mapStyles;