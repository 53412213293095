import { ConfirmChoice } from "./ConfirmChoice";
import { Date } from "./Date";
import { Map } from "./Map";

const { QuestionType } = require("enumerations/QuestionType");
const { MultipleChoice } = require("./MultipleChoice");
const { Text } = require("./Text");
const { TimeToSing } = require("./TimeToSing");

export function QuestionTypeOptions(props) {
    switch (props.data.question.type) {
        case QuestionType.MultipleChoice:
            return <MultipleChoice {...props} />
        case QuestionType.Text:
            return <Text {...props} />
        case QuestionType.TimeToSing:
            return <TimeToSing {...props} />
        case QuestionType.Date:
            return <Date {...props} />
        case QuestionType.Map:
            return <Map {...props} />
        case QuestionType.ConfirmChoice:
            return <ConfirmChoice {...props} />
        default:
            return null;
    }
}