import React, {useState} from "react";
import logo from 'assets/images/quiz-logo.png';
import { TextInput, Button } from "components";
import styles from './Join.module.css';

export function JoinName(props){
    const [name, setName] = useState("");    
    const [error, setError] = useState(null);           

    async function join() {
        setError(null);

        if(name.trim() === ""){
            setError('Please enter a name');
            return;
        }

        if(name.trim().length > 16){
            setError('Name can only be 16 characters maximum');
            return;
        }

        props.join(name);
    }

    return(
        <div className={styles.container}>
            <img src={logo} alt="Logo" />

            <div className={styles.input_group}>
                <p className={styles.input_text}>Enter your name to join the quiz</p>
                <TextInput placeholder="Name" value={name} onChange={(e) => setName(e.target.value)}/>
                {error ? <p className="error">{error}</p> : null}
            </div>

            <div className={styles.button_group}>
                <Button className="btn-submit" onClick={() => join()}>Join</Button>
            </div>
        </div>
     )

}