import React from "react";
import logo from 'assets/images/quiz-logo.png';
import styles from './Styles/AwaitingStart.module.css';

export function AwaitingStart(){

    return(
        <div className={styles.container}>
            <img src={logo} alt="Logo" />
            <p>Waiting for the quiz to start...</p>
        </div>
     )

}