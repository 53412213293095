import React from "react";
import styles from './Progress.module.css';


export function Progress(props){

    var progress = props.progress ?? 0;
    var total = props.total ?? 1;
    var width = (progress / total) * 100

    var className = styles.progress;
    if(props.className)
        className += " " + props.className;

    return(
        <div className={className}>
            <div className={styles.progress_container}>
                <span className={styles.progress_value_label}>{`${progress}/${total}`}</span>
                <div className={styles.progress_value} style={{width: `${width}%`}}/>
                {props.icon ? <span className={styles.progress_icon}>{props.icon}</span> : null}
            </div>
            { props.label ? <span className={styles.progress_label}>{props.label}</span> : null }
        </div>
    )
}