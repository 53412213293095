import React, { useState, useEffect } from "react";
import { SwitchTransition, CSSTransition } from "react-transition-group";
import { HubConnectionBuilder, HubConnectionState } from '@microsoft/signalr';

import { AudioTester, Button, ConnectionError, FullScreen } from "components";
import { HubProvider } from 'utils/hubProvider'

import { QuizState } from "enumerations/QuizState";

import quizApi from "services/QuizApi";

import { StartView } from './StartView';
import { RoundView } from './RoundView';

import styles from './Styles/Viewer.module.css';
import { QuestionView } from "./Question/QuestionView";
import { AnswerView } from "./Answer/AnswerView";
import { QuestionType } from "enumerations/QuestionType";
import { EndView } from "./EndView";
import { ScoresView } from "./ScoresView";
import { VolumeProvider } from "utils/volumeProvider";


export function Viewer() {
    const [hubConnection, setHubConnection] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState(null);
    const [volume, setVolume] = useState(null);

    const connectingRef = React.useRef(null);
    const audioRef = React.useRef(null);
    const endedRef = React.useRef(null);
    const awaitingStartRef = React.useRef(null);
    const scoresRef = React.useRef(null);

    const questionRefs = React.useRef([]);
    const answerRefs = React.useRef([]);
    const roundRefs = React.useRef([]);

    const [isReconnecting, setIsReconnecting] = useState(false);
    const [connectionFailed, setConnectionFailed] = useState(false);


    //Connect
    useEffect(() => {
        setIsLoading(true)
        async function init() {
            await connect();
            var quizData = await quizApi.quizData();
            setData(quizData.data);

            for (let index = 0; index < quizData.data?.totalQuestionCount ?? 0; index++) {
                questionRefs.current.push(questionRefs.current[index] ?? React.createRef(null));
                answerRefs.current.push(answerRefs.current[index] ?? React.createRef(null));
            }

            for (let index = 0; index < quizData.data?.totalRoundCount ?? 0; index++) {
                roundRefs.current.push(roundRefs.current[index] ?? React.createRef(null));
            }

            setIsLoading(false)
        }
        init();
    }, [])


    //Setup Hub    
    useEffect(() => {
        if (hubConnection == null)
            return;

        hubConnection.off('state-changed');
        hubConnection.on('state-changed', async () => {
            var quizData = await quizApi.quizData();
            setData(quizData.data);
        });

    }, [hubConnection])

    async function connect() {
        setConnectionFailed(false);
        setIsReconnecting(false);

        var conn = new HubConnectionBuilder()
            .withUrl(`hub/quiz-hub`)
            .withAutomaticReconnect()
            .build();

        conn.onreconnected(() => {
            setConnectionFailed(false);
            setIsReconnecting(false);
        })

        conn.onreconnecting((error) => {
            if (conn.state === HubConnectionState.Reconnecting) {
                setIsReconnecting(true);
            }
        });

        conn.onclose(async () => {
            await connect();
        });

        try {
            await conn.start();
            console.log('Connection started!');
            setHubConnection(conn);
        } catch (error) {
            console.log('Failed to start!');
            setConnectionFailed(true);
        }
    }

    async function reconnect() {
        setIsLoading(true)

        connect()

        setIsLoading(false)
    }

    var content = null;
    var key = "";
    var ref = null;

    if (isLoading) {
        content = <FullScreen text="Connecting..." />
        key = "Connecting";
        ref = connectingRef;
    }
    else if (volume == null) {
        // content = (
        //     <div className={styles.enable_audio}>
        //         <Button className="btn-submit" onClick={() => setHasInteracted(true)}>Enable Audio</Button>
        //     </div>
        // );
        content = <AudioTester onSave={setVolume}/>
        key = "audioEnable"
        ref = audioRef
    }
    else {
        switch (data.state) {
            case QuizState.AwaitingStart:
                content = <StartView data={data} />
                key = "AwaitingStart";
                ref = awaitingStartRef;
                break;
            case QuizState.RoundStart:
                content = <RoundView round={data.round} />;
                key = `RoundStart-${data.round.roundId}`;
                ref = roundRefs.current[data.round.roundId];
                break;
            case QuizState.Question:
                content = <QuestionView data={data} />
                key = `Question-${data.question.id}`;
                ref = questionRefs.current[data.question.id];
                break;
            case QuizState.Answer:
                content = <AnswerView data={data} />
                var noTransitionTypes = [QuestionType.Text, QuestionType.MultipleChoice, QuestionType.ConfirmChoice];
                if (noTransitionTypes.some(e => e === data.question.type)) {
                    key = `Question-${data.question.id}`;
                    ref = questionRefs.current[data.question.id];
                }
                else {
                    key = `Answer-${data.question.id}`;
                    ref = answerRefs.current[data.question.id];
                }
                break;
            case QuizState.Scores:
                content = <ScoresView data={data} />
                key = `Scores`;
                ref = scoresRef;
                break;
            case QuizState.End:
                content = <EndView data={data} />
                key = `End`;
                ref = endedRef;
                break
            default:
                content = null;
        }
    }

    return (
        <div className={styles.container}>
            <ConnectionError isReconnecting={isReconnecting} connectionFailed={connectionFailed} reconnect={reconnect} />
            <div className={styles.content}>
                <VolumeProvider value={volume}>
                    <HubProvider value={hubConnection}>
                        <SwitchTransition mode='in-out'>
                            <CSSTransition
                                timeout={{
                                    enter: 0,
                                    exit: 2500,
                                }}
                                key={key}
                                nodeRef={ref}
                                classNames="diag">
                                <div ref={ref} className="diag-container">{content}</div>
                            </CSSTransition>
                        </SwitchTransition>
                    </HubProvider>
                </VolumeProvider>
            </div>
        </div>
    )
}