import React from "react";
import styles from './ConnectionError.module.css';
import { Button } from "components";

export function ConnectionError(props){
  return (
    <>
      {props.isReconnecting ? <div className={styles.reconnecting}>Lost connection to server, reconnecting...</div> : null }
      {props.connectionFailed ? (
          <div className={styles.connection_failed}>
              <span>Failed to connect to server</span>
              <Button small onClick={() => props.reconnect()}>Retry</Button>
          </div>
      ) : null }
    </>
  )
}