import { Button } from "components";
import React, { useState } from "react";
import validatorApi from "services/ValidatorApi";

export function PlayerAnswer(props) {
    const [correct, setCorrect] = useState(Array(props.playerAnswer.answer.length).fill(false));
    const [submitting, setSubmitting] = useState(false);
    const [error, setError] = useState("");

    const markCorrect = (index, val) => {
        let newArr = [...correct];
        newArr[index] = val;

        setCorrect(newArr);
    }

    async function submitValidation(correct) {
        setSubmitting(true);
        setError("");

        const data = {
            "QuestionId": props.playerAnswer.questionId,
            "UserId": props.playerAnswer.userId,
            "Correct": correct
        };

        await props.hub.invoke("Validate", data)
            .catch(async (error) => {
                var res = await validatorApi.validate(data);
                if (!res.success)
                    throw new Error(res.error);
            })
            .catch((error) => {
                console.log(error);
                setError("Failed to validate...");
            });


        setSubmitting(false);
    }

    async function mention(answer) {
        setError("");

        const data = {
            "UserId": props.playerAnswer.userId,
            "Answer": answer,
        };

        var res = await validatorApi.mention(data);
        if (!res.success)
            setError(res.error);
    }


    return (
        <div className='validator-answer'>
            <div className="validator-answer-title">
                <p className='validator-user-name'>{props.player.name}</p>
                {submitting ? <div className="loader" /> : null}
            </div>

            {props.playerAnswer.answer.map((answer, index) => {
                return (
                    <div className="validator-user-content" key={index}>
                        <p className='validator-user-text'>{answer[index] === "" ? "BLANK" : answer}</p>

                        {props.playerAnswer.validated ?
                            <>
                                <p className='validated-answer'>{props.playerAnswer.correct[index] ? 'Y' : 'N'}</p>
                                <Button className='hm' small onClick={() => mention(answer)}>HM</Button>
                            </>
                            : <>
                                <Button disabled={props.playerAnswer.validated} className={correct[index] ? 'active' : ''} small onClick={() => markCorrect(index, true)}>Y</Button>
                                <Button disabled={props.playerAnswer.validated} className={!correct[index] ? 'active' : ''} small onClick={() => markCorrect(index, false)}>N</Button>
                            </>}

                    </div>
                )
            })}

            {!props.playerAnswer.validated ?
                <Button className='submit-btn' small onClick={() => submitValidation(correct)}>Submit</Button> :
                null}

            {error !== "" ? <p className="validation-error">{error}</p> : null}
        </div>
    )


}